/*-----------------------------------------------------------------------------------

Item Name: Blueberry - eCommerce html template.
Author: Maraviya Infotech
Version: 1
Copyright 2024

-----------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------
    Included CSS INDEX
-----------------------------------------------------------------------------------

01. Typography

02. Utilities
    - Mixing
    - Variables

03. components
    - Header
    - Footer
    - Common
    - Cart sidebar
    - Category popup
    - Quick view
    - Breadcrumb
    - Newsletter popup

04. Layout 
    - Hero
    - Category
    - Product
    - Deal
    - Banner one
    - Banner two
    - New product
    - Services
    - Vendors
    - Testimonials
    - Blog 2
    - Instagram

05. Pages
    - About
    - Shop page
    - Single product page
    - Contact
    - Cart page
    - Checkout page
    - Faq
    - Login
    - Register
    - Blog
    - Wishlist
    - Compare
    - Terms
    - Track

-----------------------------------------------------------------------------------*/
/* Typography ( Import Typography ) */
/*-------------------------------------------------
  Fonts Family & Style CSS
---------------------------------------------------*/
/*--  Poppins font  --*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* /* @font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Poppins, sans-serif";
  src: url("../fonts/poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
} */
/*--  Quicksand font  --*/
/*@font-face {
  font-family: "Quicksand";
  src: url("../fonts/quicksand/Quicksand-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../fonts/quicksand/Quicksand-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../fonts/quicksand/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../fonts/quicksand/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("../fonts/quicksand/Quicksand-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
} */
/* Utilities ( Import Utilities ) */
/* mixing */
/* variables */
/* components ( Import components ) */
/* common */

body {
  font-family: "Poppins, sans-serif" ;
  font-size: 15px;
  font-weight: 400;
  color: #686e7d;
  line-height: 26px;
  letter-spacing: 0.02rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

::-moz-selection {
  color: #0fa968;
  background-color: rgba(0, 0, 0, 0.04);
}

::selection {
  color: #0e7248;
  background-color: rgba(0, 0, 0, 0.04);
}

p,
li,
a {
  margin-bottom: 0;
  font-family: "Poppins, sans-serif";
  font-size: 15px;
  color: #686e7d;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0.03rem;
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "quicksand";
  margin-bottom: 0;
  color: #3d4750;
  font-weight: 600;
  letter-spacing: 0.03rem;
}

[type=radio]:checked {
  position: relative;
  opacity: 0;
  display: none;
}
[type=radio]:checked + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #686e7d;
  letter-spacing: 0;
}
[type=radio]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #0fa968;
}
[type=radio]:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0fa968;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
[type=radio]:not(:checked) {
  position: relative;
  opacity: 0;
  display: none;
}
[type=radio]:not(:checked) + label {
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  line-height: 16px;
  display: inline-block;
  color: #686e7d;
  letter-spacing: 0;
}
[type=radio]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #eee;
  border-radius: 100%;
  background: #fff;
}
[type=radio]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #0fa968;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.bb-bg {
  background-color: #f8f8fb;
}

/* Loader */
.bb-loader {
  min-width: 100%;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  z-index: 45;
}
.bb-loader img {
  position: absolute;
}
.bb-loader .loader {
  width: 60px;
  height: 60px;
  position: relative;
}
.bb-loader .loader:before {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20px;
  left: -20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px dashed #3d4750;
  border-radius: 50%;
  -webkit-animation: animloader 5s linear infinite;
          animation: animloader 5s linear infinite;
}
@-webkit-keyframes animloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes animloader {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

input,
textarea {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  border: 1px solid #eee;
  outline: 0;
  border-radius: 10px;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
input::placeholder,
textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}

.custom-dropdown {
  position: relative;
  z-index: 5;
}
.custom-dropdown:hover ul.dropdown {
  opacity: 1;
  display: block;
  visibility: visible;
  margin: 6px 0 0 0;
}
.custom-dropdown .bb-dropdown-toggle {
  position: relative;
  padding-right: 15px;
}
.custom-dropdown .bb-dropdown-toggle:after {
  content: "\ea4e";
  width: 0;
  height: 0;
  position: absolute;
  top: -1px;
  right: 12px;
  font-size: 15px;
  font-family: "remixicon";
}
.custom-dropdown ul.dropdown {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-width: 150px;
  padding: 10px 5px;
  margin: 25px 0 0 0;
  position: absolute;
  z-index: 16;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: auto !important;
  background: #fff;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
  display: block;
  border-radius: 10px;
}
.custom-dropdown ul.dropdown li a {
  padding: 0px 12px;
  font-size: 13px;
  display: block;
  color: #686e7d;
}

.section-title {
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  z-index: 5;
}
.section-title .section-detail h2 {
  margin-bottom: 0;
  padding: 0;
  font-size: 25px;
  line-height: 38px;
  font-weight: 700;
  color: #3d4750;
  position: relative;
  display: inline;
  text-transform: capitalize;
  line-height: 1;
}
.section-title .section-detail h2 span {
  color: #0fa968;
}
.section-title .section-detail p {
  max-width: 400px;
  margin: 10px 0 0 0;
  font-size: 14px;
  color: #686e7d;
  line-height: 18px;
}
.section-title .title-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.section-title .title-link a {
  color: #686e7d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.section-title .title-link a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 18px;
  margin-left: 10px;
  color: #686e7d;
}
.section-title .title-link a:hover {
  color: #0fa968;
}
.section-title .title-link a:hover i {
  color: #0fa968;
}

.bb-center {
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

/* Back to top button */
a.back-to-top {
  width: 38px;
  height: 38px;
  display: none;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 10;
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
  color: #0fa968;
  border: 1px solid #0fa968;
  text-align: center;
  font-size: 22px;
  line-height: 1.6;
}
a.back-to-top i {
  font-size: 20px;
}
a.back-to-top:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.back-to-top svg {
  width: 36px;
  height: 36px;
  position: fixed;
  right: 16px;
  bottom: 16px;
}
a.back-to-top svg path {
  fill: transparent;
  stroke: #0fa968;
  stroke-width: 5px;
}

/* Custom select */
.select {
  width: 100%;
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: #fff;
}


.custom-select {
  color: #777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  font-size: 14px;
  position: relative;
}
.custom-select svg {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
}
.custom-select svg path {
  fill: #0fa968;
}
.custom-select .custom-select {
  position: relative;
}
.custom-select .custom-select:after {
  content: "\ea4e";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 12px;
  font-size: 20px;
  font-family: "remixicon";
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.custom-select .custom-select:active,
.custom-select .custom-select .active {
  background-color: #fff;
}
.custom-select:focus {
  outline: 2px;
}

.select-options {
  min-width: 190px;
  max-height: 200px;
  margin: 0;
  padding: 10px 0;
  overflow-y: auto;
  display: none;
  position: absolute;
  top: 34px;
  right: 0;
  left: 0;
  z-index: 20;
  list-style: none;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
}
.select-options li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0;
  padding: 5px 20px !important;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  border-radius: 5px;
  font-size: 14px;
  color: #777;
}
.select-options li:hover {
  color: #0fa968;
}
.select-options li[rel=hide] {
  display: none;
}
.select-options::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 0 0 5px 0;
}
.select-options::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #9e9e9e;
}

.bb-btn-1 {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #3d4750;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #3d4750;
}
.bb-btn-1:hover {
  background: #0fa968;
  border-color: #0fa968;
  color: #fff;
}

.bb-btn-2 {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 400;
  background: #0fa968;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #0fa968;
}
.bb-btn-2:hover {
  background: transparent;
  border-color: #3d4750;
  color: #3d4750;
}

.mb-24 {
  margin-bottom: 24px;
}

.m-minus-12 {
  margin: -12px;
}

.mb-minus-24 {
  margin-bottom: -24px;
}

.padding-t-100 {
  padding: 100px 0 0 0;
}

.padding-b-100 {
  padding-bottom: 100px !important;
}

.padding-tb-100 {
  padding: 100px 0;
}

.padding-tb-50 {
  padding: 50px 0;
}

.padding-t-50 {
  padding: 50px 0 0 0;
}

.padding-b-50 {
  padding-bottom: 50px;
}

.margin-t-50 {
  margin-top: 50px;
}

.margin-tb-100 {
  margin: 100px 0;
}

.margin-tb-50 {
  margin: 50px 0;
}

.margin-b-50 {
  margin-bottom: 50px;
}

/* Responsive */
@media screen and (min-width: 1400px) {
  .bb-col-6 {
    width: 16.6666%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.6666%;
            flex: 0 0 16.6666%;
  }
}
@media screen and (min-width: 1200px) {
  .bb-col-5 {
    width: 20%;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
  }
}
@media screen and (max-width: 1199px) {
  .bb-btn-1 {
    padding: 3px 15px;
  }
  .padding-tb-100 {
    padding: 70px 0;
  }
  .padding-t-100 {
    padding-top: 70px;
  }
  .padding-b-100 {
    padding-bottom: 70px !important;
  }
  .padding-tb-50 {
    padding: 35px 0;
  }
  .padding-t-50 {
    padding-top: 35px;
  }
  .padding-b-50 {
    padding-bottom: 35px;
  }
  .margin-tb-50 {
    margin: 35px 0;
  }
  .margin-t-50 {
    margin-top: 35px;
  }
  .margin-b-50 {
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 991px) {
  .section-title {
    padding-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    text-align: center;
  }
  .section-title .section-detail {
    margin-bottom: 12px;
  }
  .section-title .section-detail p {
    margin-left: auto;
    margin-right: auto;
  }
  .p-0-991 {
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .d-none-767 {
    display: none;
  }
  .section-title .section-detail h2 {
    font-size: 23px;
  }
  .bb-deal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-deal #dealend {
    margin-top: 15px;
  }
}
@media screen and (max-width: 575px) {
  section {
    overflow: hidden;
  }
}
/* header */
.bb-header {
  position: relative;
  z-index: 5;
  border-bottom: 1px solid #eee;
}

.top-header {
  background-color: #3d4750;
  padding: 6px 0;
}
.top-header .inner-top-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.top-header .inner-top-header .col-left-bar a {
  font-size: 14px;
  color: #fff;
}
.top-header .inner-top-header .col-right-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.top-header .inner-top-header .col-right-bar .cols {
  padding: 0 12px;
}
.top-header .inner-top-header .col-right-bar .cols span, .top-header .inner-top-header .col-right-bar .cols a {
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  color: #fff;
}
.top-header .inner-top-header .col-right-bar .cols > a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  color: #fff;
}
.top-header .inner-top-header .col-right-bar .cols .dropdown a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  color: #686e7d;
  font-weight: 400;
}
.top-header .inner-top-header .col-right-bar .cols .dropdown a:hover {
  color: #0fa968;
}

.bb-header-btn {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  width: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-header-btn:after {
  display: none;
}
.bb-header-btn:not(:last-child) {
  margin-left: 30px;
}
.bb-header-btn .header-icon {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-header-btn .header-icon svg {
  width: 30px;
  height: 30px;
}
.bb-header-btn .header-icon svg path {
  fill: #0fa968;
}
.bb-header-btn .bb-btn-stitle {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
}

.bb-toggle-menu {
  display: none;
}
.bb-toggle-menu .header-icon i {
  font-size: 22px;
  color: #0fa968;
}

.header-search {
  width: 600px;
}
.header-search .bb-btn-group-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.header-search .bb-btn-group-form .inner-select {
  border-right: 1px solid #eee;
  height: 100%;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}
.header-search .bb-btn-group-form .inner-select .custom-select {
  width: 100px;
  text-transform: capitalize;
}
.header-search .bb-btn-group-form .inner-select .custom-select .select-options {
  left: -20px;
}
.header-search .bb-btn-group-form input {
  background-color: #fff;
  display: block;
  width: 100%;
  min-height: 45px;
  height: 48px;
  padding: 10px 10px 10px 160px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #777;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 10px;
  border: 1px solid #eee;
  letter-spacing: 0.5px;
}
.header-search .bb-btn-group-form input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.header-search .bb-btn-group-form input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.header-search .bb-btn-group-form input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.header-search .bb-btn-group-form input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.header-search .bb-btn-group-form input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.header-search .bb-btn-group-form button {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 45px;
  height: 100%;
  background: transparent;
  color: #555;
  font-size: 16px;
  border-radius: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
  border: 0;
  padding: 0;
}
.header-search .bb-btn-group-form button i {
  font-size: 18px;
  line-height: 12px;
  color: #555;
}

.bottom-header .inner-bottom-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bottom-header .inner-bottom-header .cols {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bottom-header .inner-bottom-header .bb-sidebar-toggle svg {
  height: 30px;
  width: 30px;
}
.bottom-header .inner-bottom-header .bb-sidebar-toggle svg path {
  fill: #0fa968;
}
.bottom-header .inner-bottom-header .bb-sidebar-toggle {
  display: none;
}
.bottom-header .inner-bottom-header .header-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bottom-header .inner-bottom-header .header-logo img {
  width: 75px;
}
.bottom-header .inner-bottom-header .header-logo .dark {
  display: none;
}
.bottom-header .inner-bottom-header .header-logo .light {
  display: block;
}
.bottom-header .inner-bottom-header .bb-header-buttons {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop {
  position: relative;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop:hover .bb-dropdown-menu {
  margin-top: 15px;
  opacity: 1;
  visibility: visible;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop .bb-dropdown-menu {
  min-width: 150px;
  padding: 10px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 25px 0 0 0;
  position: absolute;
  z-index: 16;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: auto !important;
  background: #fff;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
  display: block;
  border-radius: 10px;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop .bb-dropdown-menu li {
  padding: 4px 15px;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop .bb-dropdown-menu li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  line-height: 22px;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-acc-drop .bb-dropdown-menu li a:hover {
  color: #0fa968;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-btn-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-left: 10px;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-btn-title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  line-height: 1;
  color: #3d4750;
  margin-bottom: 4px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  font-weight: 500;
}
.bottom-header .inner-bottom-header .bb-header-buttons .bb-btn-stitle {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #3d4750;
}

.bb-main-menu-desk {
  background-color: #fff;
  padding: 5px 0;
  border-top: 1px solid #eee;
}
.bb-main-menu-desk .bb-inner-menu-desk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-main-menu-desk .bb-inner-menu-desk .header-search {
  display: none;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-header-btn {
  height: 45px;
  width: 45px;
  margin: 0 30px 0 0;
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-header-btn svg {
  height: 25px;
  width: 25px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-header-btn svg path {
  fill: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .navbar-toggler {
  display: none;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: auto;
          flex: auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li a {
  padding: 0;
  line-height: 28px;
  font-size: 15px;
  font-weight: 500;
  color: #3d4750;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li a svg {
  width: 20px;
  height: 20px;
  line-height: 18px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li a svg path {
  fill: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li:not(:last-child) {
  margin-right: 35px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li:hover .nav-link {
  color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li:hover .nav-link:after {
  border-color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav li:hover span {
  color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown {
  margin-right: 45px !important;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu {
  min-width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 25px 0 0 0;
  padding: 0 0 0 30px;
  position: absolute;
  top: 40px;
  z-index: 16;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: auto !important;
  background: #fff;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu .menu_title {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu .menu_title a {
  height: auto;
  color: #0fa968;
  font-size: 15px;
  font-weight: 500;
  display: block;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu li {
  margin: 0;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 0;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  text-transform: capitalize;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu li a:hover {
  color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .mega-menu li ul {
  width: calc(25% - 30px);
  margin-right: 30px;
  padding: 15px 0;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .bb-dropdown-item {
  position: relative;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown .bb-dropdown-item:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  width: 6px;
  height: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px solid #3d4750;
  padding-left: 3px;
  border-radius: 55%;
  position: absolute;
  top: 55%;
  right: -14px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-main-dropdown:hover .mega-menu {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown {
  margin-right: 45px !important;
  position: relative;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-item {
  position: relative;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-item:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  width: 6px;
  height: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border: 1px solid #3d4750;
  padding-left: 3px;
  border-radius: 55%;
  position: absolute;
  top: 55%;
  right: -14px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-menu {
  min-width: 205px;
  padding: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 25px 0 0 0;
  position: absolute;
  top: 40px;
  z-index: 16;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: auto !important;
  background: #fff;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-menu li {
  margin: 0;
  padding: 5px 15px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-menu li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px 0;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  text-transform: capitalize;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown .bb-dropdown-menu li:hover > a {
  color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-dropdown:hover .bb-dropdown-menu {
  margin-top: 10px;
  opacity: 1;
  visibility: visible;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown {
  cursor: pointer;
  position: relative;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown .bb-mega-item:after {
  content: "\ea6e";
  font-family: "remixicon";
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  position: absolute;
  right: 0;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown .bb-mega-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-width: 220px;
  padding: 10px;
  margin: 25px 0 0 0;
  position: absolute;
  top: 0;
  z-index: 16;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  top: -20px;
  left: 193px;
  right: auto !important;
  background: #fff;
  -webkit-box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown .bb-mega-menu li {
  margin: 0;
  padding: 5px 15px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown .bb-mega-menu li a {
  padding: 6px 0;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown .bb-mega-menu li a:hover {
  color: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu ul.navbar-nav .bb-mega-dropdown:hover .bb-mega-menu {
  margin-top: 15px;
  opacity: 1;
  visibility: visible;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select {
  width: 180px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select svg {
  margin: 0 10px;
  width: 25px;
  height: 25px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select svg path {
  fill: #0fa968;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select .custom-select {
  width: 100%;
  height: 100%;
  padding-right: 15px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select .custom-select .select {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select .custom-select .select-options {
  min-width: 175px;
  margin: 15px auto 0 auto;
  padding: 10px 0;
  left: auto;
  right: -15px;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select .custom-select .select-options li {
  padding: 5px 20px !important;
}
.bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu .inner-select .custom-select .custom-select:after {
  top: 22%;
}

/*===== Mobile menus =====*/
.bb-mobile-menu-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 16;
}

.bb-mobile-menu {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 340px;
  height: 100%;
  padding: 15px 20px 20px 20px;
  position: fixed;
  top: 0;
  right: auto;
  left: 0;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 17;
  overflow: auto;
}
.bb-mobile-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-mobile-menu::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-mobile-menu::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-mobile-menu .bb-menu-title {
  width: 100%;
  padding: 0 0 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-mobile-menu .bb-menu-title .menu_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: #3d4750;
  font-weight: 600;
}
.bb-mobile-menu .bb-menu-title .bb-close-menu {
  position: relative;
  border: 0;
  font-size: 30px;
  line-height: 1;
  color: #ff0000;
  background-color: transparent;
  border: 0;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li {
  position: relative;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li a {
  margin-bottom: 12px;
  padding: 12px 12px;
  display: block;
  text-transform: capitalize;
  color: #686e7d;
  border: 1px solid #eee;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li a:last-child() {
  margin-bottom: 0;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li .menu-toggle {
  width: 100%;
  height: 54px;
  padding-right: 10px;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  cursor: pointer;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li .menu-toggle::before {
  content: "+";
  margin-left: auto;
  font-weight: 500;
  color: #686e7d;
  font-size: 19px;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li .sub-menu {
  width: 100%;
  min-width: auto;
  padding: 0;
  margin-bottom: 10px;
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  -webkit-transition: none;
  transition: none;
  opacity: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li .sub-menu li a {
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 0px;
  text-transform: capitalize;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
}
.bb-mobile-menu .bb-menu-inner .bb-menu-content ul li .sub-menu li .sub-menu li a {
  font-weight: 400;
  padding-left: 30px;
  font-size: 14px;
  color: #777;
}
.bb-mobile-menu .bb-menu-inner .header-res-social {
  margin-top: 30px;
}
.bb-mobile-menu .bb-menu-inner .header-res-social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-mobile-menu .bb-menu-inner .header-res-social ul li {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #3d4750;
  border-radius: 10px;
}
.bb-mobile-menu .bb-menu-inner .header-res-social ul li a i {
  color: #fff;
}

.bb-mobile-menu.bb-menu-open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
}

/* Responsive */
@media only screen and (max-width: 1399px) {
  .bottom-header .inner-bottom-header .header-search {
    width: 500px;
  }
}
@media only screen and (max-width: 1199px) {
  .bb-main-menu-desk .bb-inner-menu-desk {
    position: relative;
  }
  .bb-main-menu-desk .bb-inner-menu-desk .bb-main-menu {
    position: unset;
  }
  .bottom-header .inner-bottom-header .header-search {
    width: 400px;
  }
  .bottom-header .inner-bottom-header .bb-header-buttons .bb-btn-desc {
    display: none;
  }
  .bb-header-btn .header-icon svg {
    width: 25px;
    height: 25px;
  }
  .bb-header-btn:not(:last-child) {
    margin-left: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .header-search .bb-btn-group-form {
    margin-left: 20px;
  }
  .header-search .bb-btn-group-form .inner-select {
    display: none;
  }
  .header-search .bb-btn-group-form input {
    min-height: 40px;
    height: 40px;
    padding: 10px;
  }
  .bb-category-toggle {
    margin-left: 20px;
    border: 1px solid #eee;
    width: 40px;
    height: 40px;
    border-radius: 15px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .bb-category-toggle svg {
    width: 22px;
    height: 22px;
  }
  .bottom-header .inner-bottom-header .bb-sidebar-toggle svg {
    width: 22px;
    height: 22px;
  }
  .bb-main-menu-desk {
    display: none;
  }
  .bb-main-menu-desk .bb-inner-menu-desk {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .bb-main-menu-desk .bb-inner-menu-desk .bb-dropdown-menu {
    display: none;
  }
  .bb-main-menu-desk .bb-inner-menu-desk .header-search {
    width: 500px;
    display: inline;
  }
  .bb-header-btn .header-icon svg {
    width: 22px;
    height: 22px;
  }
  .bottom-header {
    padding: 15px 0;
  }
  .bottom-header .inner-bottom-header .bb-sidebar-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bottom-header .inner-bottom-header .header-logo img {
    width: 115px;
  }
  .bottom-header .inner-bottom-header .header-search {
    width: 100%;
    min-width: 300px;
  }
  .top-header {
    display: none;
  }
  .bb-toggle-menu {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bb-main-menu {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .bb-header-btn:first-child {
    margin: 0 !important;
  }
  .inner-bottom-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .inner-bottom-header .header-search {
    padding: 15px 0;
  }
  .bb-logo-detail {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .header-search .bb-btn-group-form {
    margin: 0;
  }
}
@media only screen and (max-width: 575px) {
  .header-search .bb-btn-group-form .custom-dropdown {
    padding: 0 10px;
  }
  .bb-flex-justify {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .bb-main-menu-desk .bb-inner-menu-desk .bb-header-btn {
    display: none;
  }
  .bb-main-menu-desk .bb-inner-menu-desk .header-search {
    width: 100%;
  }
  .bottom-header .inner-bottom-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bottom-header .inner-bottom-header .bb-sidebar-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bottom-header .inner-bottom-header .header-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 480px) {
  .bb-mobile-menu {
    width: 300px;
  }
  .header-search .bb-btn-group-form .inner-select {
    display: none;
  }
  .header-search .bb-btn-group-form input {
    padding: 10px;
  }
  .bottom-header .inner-bottom-header .header-search {
    min-width: auto;
  }
}
/* Footer */
.bb-footer {
  color: #fff;
  background: #f8f8fb;
}
.bb-footer .footer-directory {
  border: 1px solid #eee;
}
.bb-footer .footer-directory .directory-title {
  margin-bottom: 24px;
  text-align: center;
}
.bb-footer .footer-directory .directory-title h4 {
  font-size: 16px;
  font-weight: 700;
  color: #3d4750;
  letter-spacing: 0;
  text-transform: uppercase;
}
.bb-footer .footer-directory .directory-contact {
  margin-bottom: -24px;
}
.bb-footer .footer-directory .directory-contact .inner-contact {
  margin-bottom: 24px;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul span {
  margin-right: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #686e7d;
  text-transform: capitalize;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 13px;
  font-weight: 400;
  color: #686e7d;
  text-transform: capitalize;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul li a:hover {
  color: #0fa968;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul li:first-child::after {
  display: none;
}
.bb-footer .footer-directory .directory-contact .inner-contact ul li:not(:last-child)::after {
  content: "|";
  padding: 0 10px;
  font-size: 14px;
  color: #686e7d;
}
.bb-footer .footer-container {
  border-top: 1px solid #eee;
}
.bb-footer .footer-top .bb-footer-company {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bb-footer .footer-top .bb-footer-company .bb-app-store {
  margin: -7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-logo {
  max-width: 144px;
  margin-bottom: 30px;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-dark-logo {
  max-width: 144px;
  margin-bottom: 30px;
  display: none;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-detail {
  max-width: 400px;
  margin-bottom: 30px;
  padding: 0;
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  color: #686e7d;
  display: inline-block;
  position: relative;
}
.bb-footer .footer-top .bb-footer-widget .app-img img {
  max-width: 140px;
  margin: 7px;
  border-radius: 5px;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-heading {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #3d4750;
  letter-spacing: 0;
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 15px;
  text-transform: capitalize;
  border-bottom: 1px solid #eee;
}
.bb-footer .footer-top .bb-footer-widget .s-head {
  display: none;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links ul {
  margin-bottom: 0;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links i {
  line-height: 0;
  font-size: 18px;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link {
  display: block;
  margin: 0;
  line-height: 1.5;
  border: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  color: #0fa968;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link:not(:last-child) {
  margin-bottom: 16px;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  color: #686e7d;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  word-break: break-all;
  letter-spacing: 0;
  font-weight: 400;
}
.bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link a:hover {
  color: #0fa968;
  opacity: 1;
}
.bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-links li:first-child {
  -webkit-box-align: self-start;
      -ms-flex-align: self-start;
          align-items: self-start;
}
.bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-links li p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-links a {
  text-transform: unset;
}
.bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-links .bb-footer-link span {
  width: 25px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-links .bb-footer-link .mt-15px {
  margin-top: 15px;
}
.bb-footer .footer-top .bb-footer-social {
  margin-bottom: 0;
}
.bb-footer .footer-top .bb-footer-social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.bb-footer .footer-top .bb-footer-social .bb-footer-widget .bb-footer-links .bb-footer-link {
  padding-right: 5px;
}
.bb-footer .footer-top .bb-footer-social .bb-footer-widget .bb-footer-links .bb-footer-link:not(:last-child) {
  margin-bottom: 0;
}
.bb-footer .footer-top .bb-footer-social .bb-footer-widget .bb-footer-links .bb-footer-link a {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #3d4750;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 15px;
}
.bb-footer .footer-top .bb-footer-social .bb-footer-widget .bb-footer-links .bb-footer-link a:hover {
  background-color: #0fa968;
}
.bb-footer .footer-top .bb-footer-social .bb-footer-widget .bb-footer-links .bb-footer-link a i {
  font-size: 16px;
  color: #fff;
}

.bb-footer-contact {
  margin-bottom: 30px;
}

.footer-bottom {
  padding: 10px 0;
  border-top: 1px solid #eee;
}
.footer-bottom .bb-bottom-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer-bottom .bb-copy {
  color: #686e7d;
  font-size: 13px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 400;
}
.footer-bottom .bb-copy a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  color: #0fa968;
}
.footer-bottom .bb-copy .site-name:hover {
  color: #3d4750;
}

.bb-heading-res {
  display: none;
}

/* Responsive footer css */
@media only screen and (max-width: 1399px) {
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link a {
    font-size: 15px;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links li p {
    font-size: 15px;
  }
  .bb-footer .footer-top .bb-footer-widget .app-img img {
    max-width: 120px;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-detail {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1199px) {
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link a {
    font-size: 14px;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links li p {
    font-size: 14px;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-detail {
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) {
  .bb-footer .footer-top .bb-footer-widget .bb-footer-dropdown {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .bb-footer .footer-top .bb-footer-company {
    margin-bottom: 24px;
  }
  .bb-footer .footer-top .container > .row {
    padding: 0;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links ul {
    margin-bottom: 15px;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-links .bb-footer-link:not(:last-child) {
    margin-bottom: 15px;
  }
  .bb-footer .footer-top .col-sm-12.col-lg-3 {
    width: 100%;
    margin-bottom: 0;
  }
  .bb-footer .footer-top .bb-footer-contact .bb-footer-widget .bb-footer-heading {
    margin-bottom: 15px;
  }
  .bb-footer-contact {
    margin-bottom: 0;
  }
  .footer-news-title {
    font-size: 20px;
  }
  .bb-subscribe-form {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58%;
            flex: 0 0 58%;
  }
  .bb-heading-res {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: block;
    text-align: right;
  }
  .bb-footer-links.bb-footer-dropdown {
    display: none;
    padding: 0 0 20px 0;
  }
  .footer-bottom-copy {
    text-align: center;
  }
  .footer-bottom {
    padding: 15px 0;
  }
  .footer-bottom .col {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
  }
  .footer-bottom .bb-bottom-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .footer-bottom .bb-bottom-info .footer-copy {
    margin-bottom: 15px;
  }
  .col.footer-bottom-right {
    margin: 15px auto;
  }
  .footer-bottom-payment.d-flex.justify-content-end {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .bb-footer .footer-top .bb-footer-widget .s-head {
    display: block;
  }
  .bb-footer .footer-top .bb-footer-widget .bb-footer-heading {
    margin-bottom: 20px;
    font-size: 14px;
  }
  .m-minus-991 {
    margin-bottom: -15px;
  }
}
@media (max-width: 767px) {
  .bb-footer .footer-top .bb-footer-widget .bb-footer-logo, .bb-footer .footer-top .bb-footer-widget .bb-footer-dark-logo {
    max-width: 130px;
  }
}
@media (max-width: 360px) {
  .footer-bottom .bb-bottom-info .footer-bottom-right img {
    width: 100%;
  }
}
/* Cart Sidebar */
.bb-side-cart-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 17;
}

.bb-open-cart {
  -webkit-transform: translateX(0) !important;
          transform: translateX(0) !important;
  opacity: 1 !important;
}

.bb-side-cart {
  width: 500px;
  height: calc(100% - 30px);
  margin: 15px 0 15px 15px;
  padding: 15px 20px 0 20px;
  font-size: 14px;
  font-weight: 400;
  position: fixed;
  z-index: 17;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
 
  -webkit-box-shadow: none;
          box-shadow: none;
  overflow: auto;
  opacity: 0;
  border-radius: 20px 0 0 20px;
}
.bb-side-cart::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-side-cart::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-side-cart::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-side-cart:hover .bb-cart-close {
  height: 25px !important;
}
.bb-side-cart .h-full {
  height: 100%;
}
.bb-side-cart .bb-inner-cart {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-sub-total {
  margin: 20px 0 0 0px;
  padding-top: 0;
  padding-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-top: 1px solid #eee;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-sub-total table {
  margin: 10px 0 0 0;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-sub-total table td {
  border: 0;
  color: #777;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-sub-total table td.price {
  text-align: right;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-sub-total table .title {
  font-weight: 500;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 20px;
}
.bb-side-cart .bb-inner-cart .bb-bottom-cart .cart-btn a {
  padding: 5px 15px;
}
.bb-side-cart .bb-top-contact .bb-cart-title {
  width: 100%;
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-side-cart .bb-top-contact .bb-cart-title h4 {
  font-size: 18px;
  font-weight: 800;
  color: #3d4750;
}
.bb-side-cart .bb-top-contact .bb-cart-title .bb-cart-close {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 16px;
  height: 20px;
  position: absolute;
  top: -20px;
  right: 0;
  border: 0;
  background-color: rgba(224, 78, 78, 0.7);
  border-radius: 10px;
  cursor: pointer;
}
.bb-side-cart .bb-top-contact .bb-cart-title .bb-cart-close:hover {
  background-color: rgb(224, 78, 78);
}
.bb-side-cart .bb-top-contact .bb-cart-title .bb-cart-close:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.bb-side-cart .cart-related {
  overflow: auto;
}
.bb-side-cart .cart-related::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-side-cart .cart-related::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-side-cart .cart-related::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-side-cart .bb-border-right {
  padding-right: 24px;
  border-right: 1px solid #eee;
}
.bb-side-cart .bb-cart-box.item {
  height: 100%;
}
.bb-side-cart .bb-cart-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items {
  margin-bottom: -24px;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li {
  margin-bottom: 24px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f8f8fb;
  border-radius: 20px;
  border: 1px solid #eee;
  position: relative;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li:hover .cart-remove-item {
  opacity: 1;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .cart-remove-item {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #3d4750;
  width: 20px;
  height: 20px;
  color: #fff;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0.5;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-pro-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 25%;
          flex: 1 0 25%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-pro-img img {
  width: 85px;
  border-radius: 10px;
  border: 1px solid #eee;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-contact {
  padding-left: 15px;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 70%;
          flex: 1 0 70%;
  overflow: hidden;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-contact .bb-cart-sub-title {
  width: 100%;
  margin-bottom: 8px;
  text-decoration: none;
  color: #3d4750;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-contact span.cart-price {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 18px;
  display: block;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-contact span.cart-price span {
  font-size: 15px;
  font-weight: 600;
  color: #777;
}
.bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-contact .qty-plus-minus {
  height: 28px;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner {
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner:hover img {
  -webkit-transform: rotate(3deg) scale(1.1);
          transform: rotate(3deg) scale(1.1);
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail {
  width: 100%;
  padding: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail h4,
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail h3 {
  color: #fff;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail h4 {
  margin-bottom: 5px;
  font-size: 15px;
  font-family: "Poppins, sans-serif";
  font-weight: 300;
  line-height: 22px;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail h3 {
  font-size: 22px;
  line-height: 30px;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail a {
  width: 100px;
  margin-top: 15px;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 10px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-side-cart .bb-cart-box .bb-cart-banner .banner .detail a:hover {
  background-color: #fff;
  color: #3d4750;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .bb-side-cart {
    width: 740px;
  }
}
@media screen and (max-width: 767px) {
  .bb-side-cart {
    width: 350px;
  }
  .bb-side-cart .bb-inner-cart .bb-cart-box {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (max-width: 575px) {
  .bb-side-cart {
    width: 300px;
  }
  .bb-side-cart .bb-cart-box ul.bb-cart-items li {
    padding: 10px;
  }
  .bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-pro-img {
    -webkit-box-flex: initial;
        -ms-flex: initial;
            flex: initial;
  }
  .bb-side-cart .bb-cart-box ul.bb-cart-items li .bb-cart-pro-img img {
    width: 50px;
  }
}
/* Category */
.bb-category-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 17;
}

.bb-open-category {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.bb-category-sidebar {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 17;
  display: none;
}

.category-sidebar {
  width: calc(100% - 30px);
  max-width: 1200px;
  margin: 15px auto;
  padding: 30px 15px;
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;

  -webkit-box-shadow: none;
          box-shadow: none;
  overflow: auto;
  border-radius: 30px;
  z-index: 18;
  position: relative;
}
.category-sidebar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.category-sidebar::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.category-sidebar::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.category-sidebar:hover button.bb-category-close {
  height: 25px;
}
.category-sidebar .sub-title {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.category-sidebar .sub-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
  text-transform: capitalize;
}
.category-sidebar button.bb-category-close {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 16px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: 27px;
  border: 0;
  background-color: rgba(224, 78, 78, 0.7);
  border-radius: 10px;
  cursor: pointer;
}
.category-sidebar button.bb-category-close:hover {
  background-color: rgb(224, 78, 78);
}
.category-sidebar button.bb-category-close:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.category-sidebar .bb-category-tags {
  margin-bottom: 24px;
}
.category-sidebar .bb-category-cart {
  padding: 15px;
  overflow: hidden;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.category-sidebar .bb-category-cart .pro-img {
  margin-right: 12px;
}
.category-sidebar .bb-category-cart .pro-img img {
  width: 80px;
  border-radius: 10px;
  border: 1px solid #eee;
}
.category-sidebar .bb-category-cart .side-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.category-sidebar .bb-category-cart .side-contact h4 {
  font-size: 15px;
}
.category-sidebar .bb-category-cart .side-contact h4 a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  color: #3d4750;
}
.category-sidebar .bb-category-cart .side-contact .inner-price {
  margin: 0 -3px;
}
.category-sidebar .bb-category-cart .side-contact .inner-price .new-price {
  padding: 0 3px;
  font-size: 15px;
  color: #686e7d;
  font-weight: 600;
}
.category-sidebar .bb-category-cart .side-contact .inner-price .old-price {
  padding: 0 3px;
  font-size: 14px;
  color: #686e7d;
  text-decoration: line-through;
}

/* Responsive */
@media screen and (max-width: 1199px) {
  .category-sidebar {
    height: calc(100vh - 60px);
  }
}
@media screen and (max-width: 767px) {
  .category-sidebar .bb-category-cart {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .category-sidebar .bb-category-cart .pro-img {
    margin: 0 0 15px 0;
  }
  .category-sidebar .bb-category-cart .pro-img img {
    width: 100%;
  }
}
/* quickview */
.modal-dialog {
  margin: 0% auto;
  max-width: 960px;
  width: 800px;
  padding: 35px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.quickview-modal .modal-body {
  padding: 24px;
}
.quickview-modal .qty-close {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 16px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: 24px;
  border: 0;
  background-color: rgba(224, 78, 78, 0.7);
  border-radius: 10px;
  cursor: pointer;
  z-index: 5;
}
.quickview-modal .qty-close:hover {
  background-color: rgb(224, 78, 78);
}
.quickview-modal .qty-close:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
}
.quickview-modal .modal-content {
  border: 0;
  border-radius: 20px;
  overflow: hidden;
}
.quickview-modal .modal-content:hover .qty-close {
  height: 25px !important;
}
.quickview-modal .modal-content .single-pro-img {
  height: 100%;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 20px;
}
.quickview-modal .modal-content .single-pro-img .single-product-scroll {
  height: 100%;
}
.quickview-modal .modal-content .single-pro-img .single-slide {
  height: 100%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

button.btn-close.qty_close {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  z-index: 9999;
}

.img-responsive {
  max-width: 100%;
  display: block;
}

.quickview-pro-content .bb-quick-title a {
  margin: 0 0 10px 0;
  display: block;
  color: #3d4750;
  font-size: 20px;
  text-decoration: none;
  line-height: 30px;
  font-weight: 500;
}
.quickview-pro-content .bb-pro-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.quickview-pro-content .bb-quickview-desc {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 24px;
  color: #777;
  font-weight: 300;
}
.quickview-pro-content .bb-quickview-price {
  padding: 5px 0 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.quickview-pro-content .bb-quickview-price span.new-price {
  color: #3d4750;
  font-weight: 700;
  font-size: 22px;
}
.quickview-pro-content .bb-quickview-price span.old-price {
  font-size: 18px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #777;
}

.bb-quickview-qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-quickview-qty .bb-quickview-cart {
  margin-left: 4px;
}
.bb-quickview-qty .bb-quickview-cart button {
  height: 40px;
  padding: 3px 20px;
}
.bb-quickview-qty .bb-quickview-cart button i {
  padding-right: 8px;
}

.bb-pro-variation {
  margin: 15px 0 25px 0;
}
.bb-pro-variation ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -2px;
}
.bb-pro-variation ul li {
  height: 22px;
  margin: 2px;
  padding: 2px 8px;
  cursor: pointer;
  border: 1px solid #eee;
  color: #777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
  line-height: 22px;
  border-radius: 20px;
  font-weight: 400;
}
.bb-pro-variation ul li a {
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  color: #777;
}
.bb-pro-variation ul li.active {
  background-color: #0fa968 !important;
  color: #fff !important;
  border-color: #0fa968 !important;
}
.bb-pro-variation ul li.active a {
  color: #fff;
}

/* Responsive footer css */
@media only screen and (max-width: 991px) {
  .modal-dialog {
    max-width: 720px;
    width: 720px;
  }
}
@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 80%;
    height: auto;
    max-width: 100%;
    padding: 35px 0;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 575px) {
  .modal-dialog {
    width: 90%;
  }
}
@media only screen and (max-width: 360px) {
  .bb-quickview-qty {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .bb-quickview-qty .qty-plus-minus {
    margin: auto;
  }
  .bb-quickview-qty .bb-quickview-cart {
    margin-top: 15px;
    margin-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* Breadcrumb */
.section-breadcrumb {
  border-bottom: 1px solid #eee;
  background-color: #f8f8fb;
}

.bb-breadcrumb-inner {
  margin: 0;
  padding: 20px 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-breadcrumb-inner h2.bb-breadcrumb-title {
  font-size: 16px;
  font-weight: 700;
  color: #3d4750;
}
.bb-breadcrumb-inner .bb-breadcrumb-list {
  margin: 0 -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.bb-breadcrumb-inner .bb-breadcrumb-list li {
  padding: 0 5px;
}
.bb-breadcrumb-inner .bb-breadcrumb-list li i {
  font-size: 14px;
  font-weight: 600;
}
.bb-breadcrumb-inner .bb-breadcrumb-list li a {
  font-size: 14px;
  font-weight: 600;
  color: #686e7d;
}
.bb-breadcrumb-inner .bb-breadcrumb-list li {
  font-size: 14px;
  font-weight: 400;
}

/* Responsive */
@media screen and (max-width: 767px) {
  .bb-breadcrumb-title {
    text-align: center;
    margin-bottom: 10px;
  }
  .bb-breadcrumb-inner .bb-breadcrumb-list {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* newsletter-popup */
.bb-popnews-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 25;
}

.bb-popnews-box {
  width: 100%;
  max-width: 600px;
  padding: 24px;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  display: none;
  z-index: 25;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;
}
.bb-popnews-box:hover .bb-popnews-close {
  height: 25px !important;
}
.bb-popnews-box .bb-popnews-box-content {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-popnews-box img {
  width: 100%;
  border-radius: 15px;
}
.bb-popnews-box h2 {
  text-decoration: none;
  color: #3d4750;
  display: block;
  font-size: 22px;
  line-height: 33px;
  font-weight: 600;
  margin: 0 auto 10px;
  letter-spacing: 0;
  text-transform: capitalize;
}
.bb-popnews-box p {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 22px;
  color: #686e7d;
}
.bb-popnews-box form {
  margin-bottom: 0px;
}
.bb-popnews-box input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #eee;
  color: #3d4750;
  font-size: 14px;
  padding: 10px 15px;
  width: 100%;
  outline: none;
  border-radius: 10px;
}
.bb-popnews-box button {
  padding: 4px 15px;
}

.bb-popnews-close {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 16px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: 15px;
  border: 0;
  background-color: rgba(224, 78, 78, 0.7);
  border-radius: 10px;
  cursor: pointer;
}
.bb-popnews-close:hover {
  background-color: rgb(224, 78, 78);
}
.bb-popnews-close:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 3px;
  left: 3px;
  border: 2px solid #fff;
  border-radius: 50%;
}

/* Responsive */
@media screen and (max-width: 767px) {
  .bb-popnews-box {
    width: 90%;
  }
  .bb-popnews-box img {
    display: none;
  }
}
/* tools-sidebar */
.bb-tools-sidebar-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 16;
}

.bb-tools-sidebar {
  width: 300px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 16;
  -webkit-transform: translateX(300px);
          transform: translateX(300px);
}
.bb-tools-sidebar .bb-tools-sidebar-toggle {
  position: absolute;
  top: 45%;
  right: 302px;
  width: 40px;
  height: 40px;
  background-color: #3d4750;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 25px;
  z-index: -1;
  text-decoration: none;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.102);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.102);
  border-radius: 5px;
}
.bb-tools-sidebar .bb-tools-sidebar-toggle i {
  font-size: 20px;
  color: #fff;
}
.bb-tools-sidebar .bb-bar-title {
  margin-bottom: 15px;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #eee;
}
.bb-tools-sidebar .bb-bar-title h6 {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  color: #3d4750;
}
.bb-tools-sidebar .bb-bar-title .close-tools {
  color: #ff0000;
  font-size: 17px;
  font-weight: 600;
}
.bb-tools-sidebar .bb-tools-detail {
  height: calc(100vh - 72px);
  padding: 0 15px 15px 15px;
  overflow: auto;
}
.bb-tools-sidebar .bb-tools-detail::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-tools-sidebar .bb-tools-detail::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-tools-sidebar .bb-tools-detail::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block h3 {
  margin: 15px 0;
  font-size: 14px;
  font-weight: 700;
  color: #3d4750;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-color {
  margin: 0;
  padding: 0;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-color li {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 5px;
  cursor: pointer;
  vertical-align: middle;
  margin: 6px;
  position: relative;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-tools-item,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-dark-item,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-tools-item,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-dark-item,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-tools-item,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-dark-item {
  width: 125px;
  margin-bottom: 10px;
  text-align: center;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-tools-item img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-dark-item img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-tools-item img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-dark-item img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-tools-item img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-dark-item img {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #eee;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-tools-item img:hover,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .bb-dark-item img:hover,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-tools-item img:hover,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .bb-dark-item img:hover,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-tools-item img:hover,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .bb-dark-item img:hover {
  border: 1px solid #0fa968;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-dark,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-box,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-rtl,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-dark,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-box,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-rtl,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-dark,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-box,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-rtl {
  position: relative;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-dark img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-box img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-rtl img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-dark img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-box img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-rtl img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-dark img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-box img,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-rtl img {
  border-color: #0fa968;
}
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-dark:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-box:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-dark .active-rtl:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-dark:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-box:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-box .active-rtl:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-dark:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-box:after,
.bb-tools-sidebar .bb-tools-detail .bb-tools-block .bb-tools-rtl .active-rtl:after {
  content: "\eb79";
  width: 30px;
  height: 30px;
  font-size: 20px;
  font-family: "remixicon";
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background-color: #0fa968;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.active-variant {
  position: relative;
}
.active-variant:after {
  font-family: "remixicon";
  content: "\eb79";
  height: 35px;
  width: 35px;
  font-size: 20px;
  position: absolute;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.color-primary {
  background: #0fa968;
}

.color-1 {
  background: #8118d5;
}

.color-2 {
  background: #5f6af5;
}

.color-3 {
  background: #f5885f;
}

.color-4 {
  background: #32dbe2;
}

.color-5 {
  background: #3f51b5;
}

.color-6 {
  background: #f44336;
}

.color-7 {
  background: #e91e63;
}

.color-8 {
  background: #607d8b;
}

.color-9 {
  background: #5eb595;
}

.open-tools {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

/* Layouts ( Import Layouts ) */
/* Hero */
.section-hero {
  padding: 50px 0;
  position: relative;
  background: linear-gradient(rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.65)), url(../../public/images/bannerButchery.jpg) center center no-repeat;
  background-position: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  backdrop-filter: blur(200px);
}
.section-hero:after {
  content: "";
  width: 800px;
  height: 1000px;
  top: 0;
  right: 0;
}
.section-hero .back-text-1, .section-hero .back-text-2 {
  position: absolute;
  font-size: 200px;
  font-weight: 700;
  line-height: 200px;
  color: #f1f1f7;
}
.section-hero .back-text-1 {
  top: 0;
}
.section-hero .back-text-2 {
  right: 0;
  bottom: 0;
}
.section-hero .bb-social-follow {
  position: absolute;
  left: 20px;
  bottom: 30px;
}
.section-hero .bb-social-follow ul.inner-links li {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  padding: 6px;
}
.section-hero .bb-social-follow ul.inner-links li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  font-weight: 500;
  color: #777;
  text-transform: uppercase;
}
.section-hero .bb-social-follow ul.inner-links li a:hover {
  color: #0fa968;
}
.section-hero .bb-scroll-Page {
  position: absolute;
  right: -15px;
  bottom: 75px;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.section-hero .bb-scroll-Page .scroll-bar {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.section-hero .bb-scroll-Page .scroll-bar:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  height: 80px;
  width: 1px;
  position: absolute;
  right: -50px;
  bottom: -33px;
  background-color: #000;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.section-hero .bb-scroll-Page .scroll-bar a {
  font-size: 16px;
  font-weight: 500;
}

.swiper-slide-active .hero-contact > *:nth-child(1) {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.swiper-slide-active .hero-contact > *:nth-child(2) {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.swiper-slide-active .hero-contact > *:nth-child(3) {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.hero-contact {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.hero-contact > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}
> .hero-contact:nth-child(1) {
  webkit-animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
> .hero-contact:nth-child(2) {
  webkit-animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
> .hero-contact:nth-child(3) {
  webkit-animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
.hero-contact p {
  margin-bottom: 20px;
  font-family: "Poppins, sans-serif";
  font-size: 18px;
  color: #777;
}
.hero-contact h1, .hero-contact h2 {
  margin-bottom: 20px;
  font-size: 50px;
  color: #3d4750;
  font-weight: 700;
}
.hero-contact h1 span, .hero-contact h2 span {
  color: #0fa968;
  position: relative;
}
.hero-contact h1 span:after, .hero-contact h2 span:after {
  content: "";
  width: 48px;
  height: 60px;
  position: absolute;
  top: -40px;
  right: -40px;
  /* background-image: url("public/images/bannerButchery"); */
  background-size: 100%;
  background-repeat: no-repeat;
}

.hero-slider .swiper-buttons {
  display: none;
}
.hero-slider .swiper-pagination {
  margin-left: 1px;
  position: relative;
  text-align: left;
  bottom: -20px;
  z-index: 1;
}
.hero-slider .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 0 2px !important;
  -webkit-transform: skew(340deg);
          transform: skew(340deg);
  display: inline-block;
  border-radius: 0;
  opacity: 1;
  background-color: #4b5966;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.hero-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0fa968;
  width: 30px;
}

.swiper-slide-active .hero-image img {
  opacity: 1;
}

.hero-image {
  padding-right: 50px;
  position: relative;
}
.hero-image img {
  width: 100%;
  padding: 0 0 50px 0;
  opacity: 0;
}
.hero-image svg {
  width: 120%;
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: -1;
}
.hero-image svg path {
  fill: #fff;
  -webkit-filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.015));
          filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.015));
}

/* Responsive footer css */
@media only screen and (max-width: 1399px) {
  .hero-image svg {
    right: -30px;
  }
}
@media only screen and (max-width: 1250px) {
  .bb-social-follow, .scroll-bar {
    display: none;
  }
}
@media only screen and (max-width: 1199px) {
  .hero-image svg {
    width: 125%;
  }
  .hero-image img {
    padding-right: 30px;
  }
  .hero-contact p {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .hero-contact h1, .hero-contact h2 {
    margin-bottom: 10px;
    font-size: 38px;
  }
  .hero-contact h1 span:after, .hero-contact h2 span:after {
    height: 60px;
    width: 40px;
    top: -35px;
    right: -35px;
  }
}
@media only screen and (max-width: 991px) {
  .hero-image {
    padding: 0 50px;
  }
  .hero-image img {
    padding-right: 0;
  }
  .hero-image svg {
    width: 100%;
    top: 0;
  }
  .hero-contact {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hero-contact h1, .hero-contact h2 {
    font-size: 45px;
    text-align: center;
  }
  .hero-contact h1 span:after, .hero-contact h2 span:after {
    height: 40px;
    width: 28px;
    top: -25px;
    right: -25px;
  }
  .hero-slider .swiper-pagination {
    display: none;
    margin: 0 0 0 -1px;
    bottom: -8px;
    text-align: right;
  }
}
@media only screen and (max-width: 767px) {
  .hero-contact h1, .hero-contact h2 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .section-hero .bb-social-follow {
    display: none;
  }
  .section-hero .bb-scroll-Page {
    display: none;
  }
  .hero-image {
    padding: 0 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .hero-image img {
    padding: 0 0 30px 0;
  }
  .hero-image svg {
    right: 0;
  }
  .hero-contact {
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hero-contact h1, .hero-contact h2 {
    font-size: 35px;
  }
  .hero-slider .swiper-pagination {
    bottom: 0;
  }
}
@media only screen and (max-width: 420px) {
  .hero-contact h1, .hero-contact h2 {
    font-size: 30px;
  }
  .hero-image {
    padding: 0;
  }
  .hero-image svg {
    width: 110%;
    right: -30px;
  }
  .hero-image img {
    padding: 0 0 15px 0;
  }
}
@media only screen and (max-width: 360px) {
  .hero-contact h1, .hero-contact h2 {
    font-size: 28px;
  }
}
/* category */
.bb-category-img {
  position: relative;
}
.bb-category-img img {
  width: 100%;
  border-radius: 30px;
}
.bb-category-img .bb-offers {
  padding: 5px 15px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #000;
  opacity: 0.8;
  border-radius: 15px;
}
.bb-category-img .bb-offers span {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.bb-category-contact .category-title {
  margin-bottom: 30px;
}
.bb-category-contact .category-title h2 {
  font-size: 152px;
  text-shadow: 1px 1px #111a24, -1px 1px #111a24, 1px -1px #111a24, -1px -1px #111a24;
  color: #fff;
  opacity: 0.15;
  font-weight: bold;
  line-height: 1.2;
}

.bb-category-block {
  margin-left: -150px;
  width: calc(100% + 150px) !important;
  padding: 30px 0 0 30px;
  background-color: #fff;
  border-top-left-radius: 30px;
  position: relative;
}
.bb-category-block:before {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  top: -29px;
  left: 97px;
  background-image: url("../../public/category/top-shape.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.bb-category-block:after {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 0;
  left: -29px;
  background-image: url("../../public/category/top-shape.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.category-items-1 {
  background-color: #fef1f1;
}

.category-items-2 {
  background-color: #e1fcf2;
}

.category-items-3 {
  background-color: #f4f1fe;
}

.category-items-4 {
  background-color: #fbf9e4;
}

.bb-category-box {
  padding: 30px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.bb-category-box:hover .category-image img, .bb-category-box:hover .category-image svg {
  -webkit-animation: zoom-in-zoom-out 0.5s ease-out;
          animation: zoom-in-zoom-out 0.5s ease-out;
}
.bb-category-box .category-image {
  margin-bottom: 12px;
}
.bb-category-box .category-image img, .bb-category-box .category-image svg {
  height: 50px;
  width: 50px;
}
@-webkit-keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
}
@keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
  }
  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    opacity: 1;
  }
}
.bb-category-box .category-sub-contact h5 {
  margin-bottom: 2px;
  font-size: 16px;
}
.bb-category-box .category-sub-contact h5 a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.03rem;
  color: #3d4750;
  text-transform: capitalize;
}
.bb-category-box .category-sub-contact p {
  font-size: 13px;
  color: #686e7d;
  line-height: 25px;
}

/* Responsive footer css */
@media only screen and (max-width: 1399px) {
  .bb-category-contact .category-title h2 {
    font-size: 95px;
  }
  .bb-category-box .category-image img {
    height: 65px;
    width: 65px;
  }
  .bb-category-box {
    padding: 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .bb-category-box .category-image img {
    height: 50px;
    width: 50px;
  }
  .bb-category-contact .category-title h2 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .bb-category-img {
    display: none;
  }
  .bb-category-contact {
    margin-top: -24px;
  }
  .bb-category-contact .category-title {
    display: none;
  }
  .bb-category-block {
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
  .bb-category-block:before, .bb-category-block:after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .bb-category-contact .category-title h2 {
    font-size: 42px;
  }
}
/* Product */
.bb-pro-box {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-pro-box:hover .bb-pro-img .flags {
  opacity: 0;
}
.bb-pro-box .bb-pro-img {
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #eee;
  z-index: 4;
}
.bb-pro-box .bb-pro-img .flags {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  z-index: 5;
  top: 10px;
  left: 6px;
}
.bb-pro-box .bb-pro-img .flags span {
  font-size: 14px;
  color: #777;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-lr;
      -ms-writing-mode: tb-lr;
          writing-mode: vertical-lr;
  text-orientation: upright;
}
.bb-pro-box .bb-pro-img .inner-img {
  position: relative;
  display: block;
  overflow: hidden;
  pointer-events: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.bb-pro-box .bb-pro-img .inner-img img {
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  max-width: 100%;
}
.bb-pro-box .bb-pro-img .inner-img img.hover-img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: 0;
}
.bb-pro-box .bb-pro-img .bb-pro-actions {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0;
}
.bb-pro-box .bb-pro-img .bb-pro-actions .bb-btn-group {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 35px;
  height: 35px;
  margin: 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
}
.bb-pro-box .bb-pro-img .bb-pro-actions .bb-btn-group a {
  width: 35px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-pro-box .bb-pro-img .bb-pro-actions .bb-btn-group i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  color: #777;
  line-height: 10px;
}
.bb-pro-box .bb-pro-img .bb-pro-actions .bb-btn-group:hover {
  background-color: #0fa968;
  border-color: #0fa968;
}
.bb-pro-box .bb-pro-img .bb-pro-actions .bb-btn-group:hover i {
  color: #fff;
}
.bb-pro-box:hover .bb-pro-img .inner-img img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.bb-pro-box:hover .bb-pro-img .inner-img img:not(:last-child) {
  opacity: 0;
}
.bb-pro-box:hover .bb-pro-img .inner-img img.hover-img {
  opacity: 1;
}
.bb-pro-box:hover .bb-pro-img .bb-pro-actions {
  opacity: 1;
  bottom: 10px;
}
.bb-pro-box .bb-pro-contact {
  padding: 20px;
}
.bb-pro-box .bb-pro-contact .bb-pro-subtitle {
  margin-bottom: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-pro-box .bb-pro-contact .bb-pro-subtitle span, .bb-pro-box .bb-pro-contact .bb-pro-subtitle a {
  font-family: "Poppins, sans-serif";
  font-size: 13px;
  line-height: 16px;
  color: #777;
}
.bb-pro-box .bb-pro-contact h4.bb-pro-title {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 18px;
}
.bb-pro-box .bb-pro-contact h4.bb-pro-title a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  font-family: "quicksand";
  font-size: 15px;
  line-height: 18px;
  color: #3d4750;
  font-weight: 600;
}
.bb-pro-box .bb-pro-contact h4.bb-pro-title a:hover {
  color: #0fa968;
}
.bb-pro-box .bb-pro-contact p {
  display: none;
  font-size: 14px;
}
.bb-pro-box .bb-pro-contact .bb-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-pro-box .bb-pro-contact .bb-price .inner-price {
  margin: 0 -3px;
}
.bb-pro-box .bb-pro-contact .bb-price .inner-price .new-price {
  padding: 0 3px;
  font-size: 16px;
  color: #686e7d;
  font-weight: bold;
}
.bb-pro-box .bb-pro-contact .bb-price .inner-price .item-left {
  padding: 0 3px;
  font-size: 14px;
  color: #0fa968;
}
.bb-pro-box .bb-pro-contact .bb-price .inner-price .old-price {
  padding: 0 3px;
  font-size: 14px;
  color: #686e7d;
  text-decoration: line-through;
}
.bb-pro-box .bb-pro-contact .bb-price .last-items {
  font-size: 14px;
  color: #686e7d;
}
.bb-pro-box .dealend-timer {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.bb-pro-box .dealend-timer .dealend-timer {
  padding: 5px;
  background-color: #f8f8fb;
  border-bottom: 1px solid #eee;
}
.bb-pro-box .dealend-timer .time-block {
  margin-left: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 28px;
  color: #3d4750;
}
.bb-pro-box .dealend-timer .time-block .day {
  margin: 0 5px;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 28px;
  color: #3d4750;
}
.bb-pro-box .dealend-timer .time-block .time {
  min-width: 20px;
  position: relative;
}
.bb-pro-box .dealend-timer .time-block .dots {
  margin-top: -1px;
  margin-left: 5px;
  color: #3d4750;
}

.qty-plus-minus {
  width: 85px;
  height: 40px;
  padding: 7px 0;
  border: 1px solid #eee;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #fff;
  border-radius: 10px;
}
.qty-plus-minus .bb-qtybtn {
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  color: #777;
}
.qty-plus-minus .qty-input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #777;
  float: left;
  font-size: 14px;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 32px;
  outline: none;
  font-weight: 400;
  line-height: 35px;
}

.bb-pro-rating i {
  color: #777;
  float: left;
  font-size: 15px;
  margin-right: 3px;
}
.bb-pro-rating .ri-star-fill {
  color: #fea99a;
}

/* Responsive footer css */
@media only screen and (max-width: 480px) {
  .bb-product-box {
    width: 100%;
  }
}
/* Deal */
.bb-deal-slider {
  margin: -12px;
}
.bb-deal-slider .bb-deal-card {
  padding: 12px;
}

.bb-deal {
  margin-bottom: 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-deal #dealend {
  padding: 0;
}
.bb-deal .dealend-timer {
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  position: relative;
}
.bb-deal .dealend-timer .dealend-timer {
  background-color: #f8f8fb;
  border-radius: 15px;
  border: 1px solid #eee;
}
.bb-deal .dealend-timer .time-block {
  margin-left: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #3d4750;
}
.bb-deal .dealend-timer .time-block .day {
  margin-left: 5px;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 28px;
  color: #3d4750;
}
.bb-deal .dealend-timer .time-block .time {
  min-width: 20px;
  position: relative;
}
.bb-deal .dealend-timer .time-block .dots {
  margin-top: -1px;
  margin-left: 5px;
  color: #3d4750;
}

/* Responsive footer css */
@media only screen and (max-width: 991px) {
  .bb-deal .dealend-timer {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* Banner-one */
.bg-box-color-one {
  background-color: #fbf2e5;
}
.bg-box-color-one:before {
  background-color: #f4dab4;
}
.bg-box-color-one:after {
  background-color: #f4dab4;
}

.bg-box-color-two {
  background-color: #ffe8ee;
}
.bg-box-color-two:before {
  background-color: #ffc6ce;
}
.bg-box-color-two:after {
  background-color: #ffc6ce;
}

.banner-box {
  padding: 30px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}
.banner-box:hover:before {
  left: -210px;
  top: -210px;
}
.banner-box:hover:after {
  right: -80px;
  bottom: -110px;
}
.banner-box:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  width: 400px;
  height: 600px;
  position: absolute;
  left: -200px;
  top: -200px;
  bottom: 0;
  rotate: 40deg;
  z-index: 0;
}
.banner-box:after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  width: 150px;
  height: 300px;
  position: absolute;
  right: -70px;
  bottom: -100px;
  rotate: 40deg;
  z-index: 0;
}
.banner-box .inner-banner-box {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.banner-box .inner-banner-box .side-image {
  padding: 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.banner-box .inner-banner-box .side-image img {
  width: 280px;
  height: 280px;
}
.banner-box .inner-banner-box .inner-contact {
  max-width: 250px;
  padding: 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.banner-box .inner-banner-box .inner-contact h5 {
  margin-bottom: 15px;
  font-size: 31px;
  font-weight: bold;
  color: #3d4750;
}
.banner-box .inner-banner-box .inner-contact p {
  margin-bottom: 15px;
  font-size: 16px;
  color: #686e7d;
}
.banner-box .inner-banner-box .inner-contact a {
  padding: 5px 15px;
}

/* Responsive footer css */
@media only screen and (max-width: 1399px) {
  .banner-box .inner-banner-box .side-image img {
    width: 230px;
    height: 230px;
  }
}
@media only screen and (max-width: 1199px) {
  .banner-box .inner-banner-box .side-image img {
    width: 140px;
    height: 140px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-box .inner-banner-box .side-image img {
    width: 280px;
    height: 280px;
  }
  .banner-box .inner-banner-box .inner-contact h5 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-box .inner-banner-box .side-image img {
    width: 200px;
    height: 200px;
  }
  .banner-box:before {
    left: -300px;
  }
  .banner-box:after {
    right: -100px;
  }
  .banner-box:hover:before {
    left: -310px;
  }
  .banner-box:hover:after {
    right: -110px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-box .inner-banner-box .side-image img {
    width: 100%;
    height: auto;
  }
  .banner-box .inner-banner-box .inner-contact h5 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 480px) {
  .banner-box .inner-banner-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .banner-box .inner-banner-box .side-image {
    padding: 0;
    margin-bottom: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .banner-box .inner-banner-box .side-image img {
    width: calc(100% - 70px);
  }
  .banner-box .inner-banner-box .inner-contact {
    padding: 0;
    max-width: 100%;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .banner-box .inner-banner-box .inner-contact h5 {
    margin-bottom: 2px;
    font-size: 22px;
  }
  .banner-box .inner-banner-box .inner-contact p {
    margin-bottom: 8px;
    font-size: 14px;
  }
}
/* Banner-two */
.section-banner-two {
  /* background-image: url("../img/banner-two/banner.jpg"); */
  min-height: 600px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner-justify-box-contact {
  width: 100%;
  height: 600px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.banner-two-box {
  background-color: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  max-width: 400px;
  padding: 30px 30px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  position: relative;
}
.banner-two-box:before {
  content: "";
  /* background-image: url("../img/banner-two/left-shape.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  left: -30px;
  bottom: 0;
}
.banner-two-box:after {
  content: "";
  /* background-image: url("../img/banner-two/right-shape.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  right: -30px;
  bottom: 0;
}
.banner-two-box span {
  font-size: 20px;
  font-weight: 600;
  color: #0fa968;
}
.banner-two-box h4 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: bold;
  color: #3d4750;
}

/* Responsive footer css */
@media only screen and (max-width: 991px) {
  .banner-two-box {
    max-width: 300px;
  }
  .banner-two-box span {
    font-size: 18px;
  }
  .banner-two-box h4 {
    font-size: 30px;
  }
  .section-banner-two {
    max-height: 400px;
    min-height: auto;
  }
  .banner-justify-box-contact {
    height: 400px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-two-box {
    max-width: 250px;
  }
  .banner-two-box span {
    font-size: 16px;
  }
  .banner-two-box h4 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-two-box {
    margin: 0 auto;
  }
}
/* new product */
.bb-pro-tab ul.bb-pro-tab-nav {
  margin: 0 -20px;
}
.bb-pro-tab ul.bb-pro-tab-nav .nav-item .nav-link {
  padding: 0 20px;
  font-size: 16px;
  color: #686e7d;
  font-weight: 500;
  text-transform: capitalize;
}
.bb-pro-tab ul.bb-pro-tab-nav .nav-item a.nav-link {
  color: #0fa968;
}
.bb-pro-tab ul.bb-pro-tab-nav .nav-item:not(:last-child) {
  position: relative;
}
.bb-pro-tab ul.bb-pro-tab-nav .nav-item:not(:last-child):after {
  content: "/";
  position: absolute;
  right: -4px;
  top: 0;
  font-size: 12px;
  color: #686e7d;
}

/*  Responsive  */
@media only screen and (max-width: 991px) {
  .bb-pro-tab ul.bb-pro-tab-nav {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
/* services */
.bb-services-box {
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 20px;
  text-align: center;
}
.bb-services-box .services-img {
  margin-bottom: 20px;
}
.bb-services-box .services-img img {
  width: 50px;
}
.bb-services-box .services-contact h4 {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #3d4750;
}
.bb-services-box .services-contact p {
  font-size: 14px;
  line-height: 20px;
  color: #686e7d;
}

/* vendors */
ul.bb-vendors-tab-nav {
  margin-bottom: -24px;
}
ul.bb-vendors-tab-nav li {
  width: 100%;
  margin-bottom: 24px;
}
ul.bb-vendors-tab-nav li a {
  padding: 30px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 30px;
}
ul.bb-vendors-tab-nav li a.active {
  border: 1px solid #0fa968;
}
ul.bb-vendors-tab-nav li a.active .bb-vendors-box .inner-heading h5 {
  color: #0fa968;
}

.bb-vendors-img {
  position: sticky;
  top: 0;
}
.bb-vendors-img .bb-vendor-init {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 20px;
  top: 20px;
  height: 35px;
  width: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
}
.bb-vendors-img .bb-vendor-init i {
  font-size: 20px;
  color: #fff;
}
.bb-vendors-img .bb-vendor-init:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.bb-vendors-img img {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #eee;
}
.bb-vendors-img .vendors-local-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 120px;
  width: 120px;
  background-color: #fff;
  padding: 20px 0 0 20px;
  border-top-left-radius: 30px;
}
.bb-vendors-img .vendors-local-shape img {
  width: 100px;
  height: 100px;
}
.bb-vendors-img .vendors-local-shape .inner-shape {
  position: relative;
}
.bb-vendors-img .vendors-local-shape .inner-shape:before {
  content: "";
  /* background-image: url("../img/banner-two/left-shape.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  right: 0;
  top: -50px;
}
.bb-vendors-img .vendors-local-shape .inner-shape:after {
  content: "";
  /* background-image: url("../img/banner-two/left-shape.png"); */
  background-repeat: no-repeat;
  background-size: 100%;
  height: 30px;
  width: 30px;
  position: absolute;
  z-index: 1;
  left: -50px;
  bottom: -100px;
}

.bb-vendors-box .inner-heading {
  margin-bottom: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-vendors-box .inner-heading h5 {
  font-size: 18px;
  color: #3d4750;
  font-weight: bold;
}
.bb-vendors-box .inner-heading span {
  font-size: 14px;
  color: #686e7d;
  font-weight: 400;
}
.bb-vendors-box p {
  font-size: 14px;
  line-height: 20px;
  color: #686e7d;
}

/* Responsive footer css */
@media only screen and (max-width: 420px) {
  .bb-vendors-box .inner-heading {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
/* Testimonials */
.bb-testimonials {
  position: relative;
}
.bb-testimonials .inner-banner {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  position: absolute;
  top: 0;
  z-index: -1;
  left: 150px;
  bottom: 0;
}
.bb-testimonials .inner-banner h4 {
  text-shadow: 1px 1px #111a24, -1px 1px #111a24, 1px -1px #111a24, -1px -1px #111a24;
  color: #fff;
  opacity: 0.15;
  font-size: 42px;
  font-weight: 700;
}
.bb-testimonials .testimonials-img-1 {
  z-index: -1;
  height: 70px;
  width: 70px;
  position: absolute;
  top: 0;
  left: 25px;
  border-radius: 20px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.bb-testimonials .testimonials-img-2 {
  z-index: -1;
  position: absolute;
  height: 50px;
  width: 50px;
  bottom: 0;
  left: 0;
  border-radius: 15px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.bb-testimonials .testimonials-img-3 {
  z-index: -1;
  position: absolute;
  height: 60px;
  width: 60px;
  top: -50px;
  right: 500px;
  border-radius: 20px;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.bb-testimonials .testimonials-img-4 {
  z-index: -1;
  position: absolute;
  height: 60px;
  width: 60px;
  top: 50px;
  right: 250px;
  border-radius: 20px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
}
.bb-testimonials .testimonials-img-5 {
  z-index: -1;
  position: absolute;
  height: 70px;
  width: 70px;
  top: 0;
  right: 20px;
  border-radius: 20px;
  -webkit-filter: blur(3px);
          filter: blur(3px);
}
.bb-testimonials .testimonials-img-6 {
  z-index: -1;
  position: absolute;
  height: 60px;
  width: 60px;
  bottom: 30px;
  right: 100px;
  border-radius: 20px;
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
}
.bb-testimonials .bb-testimonials-inner {
  max-width: 900px;
  margin: auto;
}
.bb-testimonials .bb-testimonials-inner .testimonials-image {
  position: relative;
}
.bb-testimonials .bb-testimonials-inner .testimonials-image img {
  width: 100%;
  border-radius: 30px;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact img {
  border-radius: 15px;
  display: none;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact h4 {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact span {
  font-size: 14px;
  color: #777;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact .inner-contact {
  background-color: #fff;
  margin-top: 10px;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 30px;
}
.bb-testimonials .bb-testimonials-inner .testimonials-contact .inner-contact p {
  font-weight: 300;
  font-size: 14px;
  line-height: 25px;
}

/* Responsive */
@media screen and (max-width: 1399px) {
  .bb-testimonials .testimonials-img-1 {
    height: 60px;
    width: 60px;
    left: 10px;
  }
  .bb-testimonials .testimonials-img-4 {
    top: 20px;
  }
  .bb-testimonials .testimonials-img-6 {
    height: 50px;
    width: 50px;
    right: 50px;
  }
  .bb-testimonials .inner-banner {
    left: 110px;
  }
  .bb-testimonials .inner-banner h4 {
    font-size: 38px;
  }
  .bb-testimonials .bb-testimonials-inner {
    max-width: 800px;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-image .inner-banner h4 {
    font-size: 36px;
  }
}
@media screen and (max-width: 1199px) {
  .section-testimonials:after {
    height: 150px;
    width: 150px;
  }
  .bb-testimonials .testimonials-img-1 {
    display: none;
  }
  .bb-testimonials .testimonials-img-2 {
    display: none;
  }
  .bb-testimonials .testimonials-img-6 {
    right: 0;
  }
  .bb-testimonials .inner-banner {
    left: 30px;
  }
  .bb-testimonials .inner-banner h4 {
    font-size: 34px;
  }
}
@media screen and (max-width: 991px) {
  .bb-testimonials .testimonials-img-3 {
    display: none;
  }
  .bb-testimonials .testimonials-img-4 {
    display: none;
  }
  .bb-testimonials .testimonials-img-5 {
    display: none;
  }
  .bb-testimonials .testimonials-img-6 {
    display: none;
  }
  .bb-testimonials .inner-banner {
    display: none;
  }
  .section-testimonials:after {
    height: 120px;
    width: 120px;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-image .inner-banner {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .bb-testimonials .bb-testimonials-inner .testimonials-contact .user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-contact img {
    max-width: 60px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-contact h4 {
    margin-bottom: 4px;
    font-size: 18px;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-contact span {
    font-size: 14px;
  }
}
@media screen and (max-width: 575px) {
  .section-testimonials:after {
    height: 80px;
    width: 80px;
  }
  .bb-testimonials .bb-testimonials-inner .testimonials-image {
    margin-bottom: 20px;
    max-width: 200px;
  }
}
/* blog-2 */
.blog-2-card {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}
.blog-2-card:hover .blog-contact {
  background-color: rgb(255, 255, 255);
}
.blog-2-card:hover .blog-img img {
  -webkit-transform: scale(1.1) rotate(3deg);
          transform: scale(1.1) rotate(3deg);
}
.blog-2-card .blog-img img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}
.blog-2-card .blog-contact {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 5px;
  padding: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
}
.blog-2-card .blog-contact span {
  font-size: 13px;
  color: #686e7d;
}
.blog-2-card .blog-contact h4 {
  font-size: 16px;
}
.blog-2-card .blog-contact h4 a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: #3d4750;
}

/* Instagram */
.section-instagram {
  position: relative;
}
.section-instagram .bb-title {
  margin: auto;
  padding: 15px 30px;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.section-instagram .bb-title h3 {
  font-size: 28px;
}

.bb-instagram-card .instagram-img {
  position: relative;
  overflow: hidden;
  border-radius: 30px;
}
.bb-instagram-card .instagram-img::before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  opacity: 0;
  border-radius: 30px;
}
.bb-instagram-card .instagram-img:hover::before {
  opacity: 1;
}
.bb-instagram-card .instagram-img:hover::after {
  opacity: 1;
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
}
.bb-instagram-card .instagram-img img {
  width: 100%;
  border-radius: 20px;
}
.bb-instagram-card .instagram-img::after {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: "remixicon";
  content: "\ee66";
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  opacity: 0;
  font-size: 38px;
  color: #fff;
  -webkit-transform: scale(3) rotate(50deg);
          transform: scale(3) rotate(50deg);
}

/* Responsive footer css */
@media only screen and (max-width: 991px) {
  .section-instagram .bb-title {
    padding: 8px 20px;
  }
  .section-instagram .bb-title h3 {
    font-size: 25px;
  }
}
/* Pages ( Import Pages ) */
/* About */
.bb-about-img {
  position: sticky;
  top: 0;
}
.bb-about-img img {
  width: 100%;
}

.bb-about-contact {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-about-contact .section-title {
  margin-bottom: 0;
  padding-bottom: 12px;
}
.bb-about-contact .about-inner-contact {
  margin-bottom: 14px;
}
.bb-about-contact .about-inner-contact h4 {
  margin-bottom: 20px;
  font-size: 18px;
  color: #3d4750;
  font-weight: 700;
  font-style: italic;
}
.bb-about-contact .about-inner-contact p {
  margin-bottom: 16px;
  font-size: 14px;
}

.bb-vendor-rows .bb-vendor-box {
  padding: 20px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-vendor-rows .bb-vendor-box .bb-count {
  margin-bottom: 12px;
}
.bb-vendor-rows .bb-vendor-box .bb-count h5 {
  font-size: 32px;
  line-height: 24px;
  color: #3d4750;
  font-weight: 700;
}
.bb-vendor-rows .bb-vendor-box .inner-text p {
  font-size: 14px;
  line-height: 18px;
  color: #686e7d;
  margin-bottom: 0;
}

.bb-team-box:hover .bb-team-img .bb-team-socials {
  right: 0;
}
.bb-team-box .bb-team-img {
  margin-bottom: 20px;
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}
.bb-team-box .bb-team-img .bb-team-socials {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  position: absolute;
  right: -38px;
}
.bb-team-box .bb-team-img .bb-team-socials .inner-shape {
  position: relative;
}
.bb-team-box .bb-team-img .bb-team-socials .inner-shape:before {
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  top: -20px;
  right: 0;
  background-size: 100%;
  /* background-image: url("../img/team/top-shape.png"); */
  background-repeat: no-repeat;
}
.bb-team-box .bb-team-img .bb-team-socials .inner-shape:after {
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  top: 147px;
  right: 0;
  background-size: 100%;
  /* background-image: url("../img/team/bottom-shape.png"); */
  background-repeat: no-repeat;
}
.bb-team-box .bb-team-img .bb-team-socials ul {
  margin-bottom: 0;
  padding: 20px 10px;
}
.bb-team-box .bb-team-img .bb-team-socials ul li:not(:last-child) {
  padding-bottom: 10px;
}
.bb-team-box .bb-team-img .bb-team-socials ul li a i {
  font-size: 16px;
}
.bb-team-box .bb-team-img .bb-team-socials ul li a i:hover {
  color: #0fa968;
}
.bb-team-box .bb-team-img img {
  width: 100%;
  border-radius: 20px;
}
.bb-team-box .bb-team-contact {
  text-align: center;
}
.bb-team-box .bb-team-contact h5 {
  font-size: 18px;
  font-weight: 700;
  color: #3d4750;
}
.bb-team-box .bb-team-contact p {
  font-size: 15px;
  color: #686e7d;
}

/* Responsive footer css */
@media only screen and (max-width: 1399px) {
  .bb-vendor-rows .bb-vendor-box .bb-count {
    margin-bottom: 8px;
  }
  .bb-vendor-rows .bb-vendor-box .bb-count h5 {
    font-size: 28px;
  }
  .bb-vendor-rows .bb-vendor-box .inner-text p {
    font-size: 14px;
  }
}
/* Shop */
.bnr-details-bg {
  background-color: #f8f8fb;
  padding: 24px;
  border-radius: 20px;
  border: 1px solid #eee;
}

ul.bb-shop-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: -5px;
}
ul.bb-shop-tags li {
  margin: 5px;
}
ul.bb-shop-tags li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 15px;
  border: 1px solid #eee;
  padding: 10px 20px;
}
ul.bb-shop-tags li a:hover {
  color: #0fa968;
  border-color: #0fa968;
}
ul.bb-shop-tags li.active-tags a {
  color: #0fa968;
  border-color: #0fa968;
}

.bb-shop-overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 17;
}

.bb-shop-sidebar {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 300px;
  height: 100vh;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 17;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  overflow: auto;
}
.bb-shop-sidebar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-shop-sidebar::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-shop-sidebar::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-shop-sidebar .sidebar-filter-title {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-shop-sidebar .sidebar-filter-title h5 {
  font-size: 18px;
  font-weight: 700;
}
.bb-shop-sidebar .sidebar-filter-title .filter-close {
  font-size: 22px;
  font-weight: 500;
  color: #ff0000;
}
.bb-shop-sidebar .bb-shop-wrap {
  border-radius: 0;
}

.bb-shop-sidebar-open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.bb-bnr-details {
  position: relative;
}
.bb-bnr-details .bb-image img {
  width: 100%;
  border-radius: 20px;
}
.bb-bnr-details .overlay-bnr {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  border-radius: 20px;
}
.bb-bnr-details .inner-contact {
  width: 100%;
  height: 100%;
  padding: 30px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.bb-bnr-details .inner-contact h4 {
  font-family: "Poppins, sans-serif";
  font-size: 38px;
  font-weight: 700;
  color: #fff;
}
.bb-bnr-details .inner-contact p {
  font-family: "Poppins, sans-serif";
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  text-align: right;
  color: #fff;
}

.width-50 {
  -webkit-box-flex: 0 !important;
      -ms-flex: 0 0 auto !important;
          flex: 0 0 auto !important;
  width: 50% !important;
}
.width-50 .bb-pro-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.width-50 .bb-pro-box .bb-pro-img {
  max-width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #fff;
  border-bottom: 0;
  border-right: 1px solid #eee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.width-50 .bb-pro-box .bb-pro-img .inner-img {
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.width-50 .bb-pro-box .bb-pro-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.width-50 .bb-pro-box .bb-pro-contact p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.width-100 {
  -webkit-box-flex: 0 !important;
      -ms-flex: 0 0 auto !important;
          flex: 0 0 auto !important;
  width: 100% !important;
}
.width-100 .bb-pro-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.width-100 .bb-pro-box .bb-pro-img {
  max-width: 250px;
  border-bottom: 0;
  border-right: 1px solid #eee;
}
.width-100 .bb-pro-box .bb-pro-img .inner-img {
  border-top-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.width-100 .bb-pro-box .bb-pro-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.width-100 .bb-pro-box .bb-pro-contact p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.bb-pro-pagination {
  margin-bottom: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-pro-pagination p {
  font-size: 15px;
}
.bb-pro-pagination ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-pro-pagination ul li:not(:last-child) {
  margin-right: 6px;
}
.bb-pro-pagination ul li a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 32px;
  height: 32px;
  padding: 0;
  font-weight: 300;
  color: #777;
  line-height: 32px;
  background: #f8f8fb;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  vertical-align: top;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  border: 1px solid #eee;
}
.bb-pro-pagination ul li a:hover {
  background-color: #3d4750;
  color: #fff;
}
.bb-pro-pagination ul li a.next {
  width: auto;
  padding: 0 13px;
  color: #fff;
  background-color: #3d4750;
  line-height: 30px;
}
.bb-pro-pagination ul li a.next i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-left: 10px;
  font-size: 16px;
  width: 8px;
  color: #fff;
}
.bb-pro-pagination ul li.active a {
  background-color: #3d4750;
  color: #fff;
}

.bb-pro-list-top {
  margin-bottom: 24px;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
.bb-pro-list-top .row {
  width: 100%;
}
.bb-pro-list-top .bb-bl-btn {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-pro-list-top .bb-bl-btn button {
  height: 38px;
  width: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 0;
  padding: 5px;
  background: transparent;
}
.bb-pro-list-top .bb-bl-btn button i {
  font-size: 20px;
}
.bb-pro-list-top .bb-bl-btn button:not(:last-child) {
  margin-right: 5px;
}
.bb-pro-list-top .bb-bl-btn button.active {
  background-color: #0fa968;
  color: #fff;
  border-radius: 15px;
}
.bb-pro-list-top .bb-select-inner {
  height: 100%;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.bb-pro-list-top .bb-select-inner .custom-select {
  width: 130px;
  margin-right: 30px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-pro-list-top .bb-select-inner .custom-select:after {
  right: -10px;
}

.bb-sidebar-contact ul {
  margin-bottom: 0;
}
.bb-sidebar-contact ul li {
  position: relative;
  display: block;
}
.bb-sidebar-contact ul li:not(:last-child) {
  margin-bottom: 14px;
}

.bb-sidebar-block-item {
  position: relative;
}
.bb-sidebar-block-item input {
  width: 100%;
  height: calc(100% - 5px);
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.bb-sidebar-block-item input:checked ~ .checked::after {
  display: block;
}
.bb-sidebar-block-item a {
  margin-left: 30px;
  display: block;
  color: #777;
  font-size: 14px;
  margin-top: 0;
  line-height: 20px;
  font-weight: 400;
  text-transform: capitalize;
  cursor: pointer;
}
.bb-sidebar-block-item .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
.bb-sidebar-block-item .checked:after {
  left: -1px;
  top: -1px;
  width: 18px;
  height: 18px;
  border: 1px solid #0fa968;
  content: "\eb7b";
  position: absolute;
  display: none;
  background: #0fa968;
  font-family: "remixicon";
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  line-height: 16px;
  text-align: center;
}

.bb-color-contact ul {
  margin-bottom: 0;
}
.bb-color-contact ul li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 2px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 5px;
  width: 26px;
  height: 26px;
}
.bb-color-contact ul li .bb-sidebar-block-item {
  position: relative;
}
.bb-color-contact ul li .bb-sidebar-block-item span {
  width: 22px;
  height: 22px;
  display: block;
  border-radius: 20px;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-1 {
  background-color: #c4d6f9;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-2 {
  background-color: #ff748b;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-3 {
  background-color: #000000;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-4 {
  background-color: #2bff4a;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-5 {
  background-color: #ff7c5e;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-6 {
  background-color: #f155ff;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-7 {
  background-color: #ffef00;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-8 {
  background-color: #c89fff;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-9 {
  background-color: #7bfffa;
}
.bb-color-contact ul li .bb-sidebar-block-item .pro-color-10 {
  background-color: #56ffc1;
}
.bb-color-contact ul li.color-sidebar-active .bb-sidebar-block-item span:after {
  width: 22px;
  height: 22px;
  content: "\eb7b";
  font-family: "remixicon";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.price-range-slider {
  position: relative;
  width: 100%;
  margin-bottom: 7px;
}
.price-range-slider .range-value {
  margin: 0;
}
.price-range-slider .range-value input {
  width: 100%;
  background: #fff;
  color: #000;
  font-size: 16px;
  font-weight: initial;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #eee;
  margin: 0 0 15px 0;
  text-align: center;
}
.price-range-slider .range-bar {
  border: none;
  background: #000;
  height: 3px;
  width: 96%;
  margin-left: 8px;
}
.price-range-slider .range-bar .ui-slider-range {
  background: #0fa968;
}
.price-range-slider .range-bar .ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #fff;
  top: -7px;
  border: 2px solid #0fa968;
  height: 17px;
  width: 17px;
  cursor: pointer;
}
.price-range-slider .range-bar .ui-slider-handle + span {
  background: #0fa968;
}

.bb-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -5px;
}
.bb-tags ul li {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 5px;
  padding: 2px 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}
.bb-tags ul li a {
  font-size: 13px;
  text-transform: capitalize;
}
.bb-tags ul li:hover {
  background-color: #0fa968;
}
.bb-tags ul li:hover a {
  color: #fff;
}

.bb-shop-wrap {
  position: sticky;
  top: 0;
  background-color: #f8f8fb;
  border-radius: 20px;
  border: 1px solid #eee;
}
.bb-shop-wrap .bb-sidebar-block {
  padding: 20px;
}
.bb-shop-wrap .bb-sidebar-block:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.bb-shop-wrap .bb-sidebar-block .bb-sidebar-title {
  margin-bottom: 20px;
}
.bb-shop-wrap .bb-sidebar-block .bb-sidebar-title h3 {
  font-size: 18px;
  font-weight: 700;
  color: #3d4750;
}

/* Responsive footer css */
@media only screen and (max-width: 1199px) {
  .width-50 {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .bnr-details-bg {
    padding: 15px;
  }
  .width-100 .bb-pro-box {
    display: block;
    overflow: hidden;
  }
  .width-100 .bb-pro-box .bb-pro-img {
    max-width: 100%;
    border-bottom: 1px solid #eee;
    border-right: 0;
  }
  .width-100 .bb-pro-box .bb-pro-img .inner-img {
    border-bottom-left-radius: 20px;
  }
  .width-100 .bb-pro-box .bb-pro-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .width-100 .bb-pro-box .bb-pro-contact p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media only screen and (max-width: 575px) {
  .width-50 .bb-pro-box {
    max-width: 380px;
    margin: auto;
    display: block;
  }
  .width-50 .bb-pro-box .bb-pro-img {
    max-width: 100%;
    border-bottom: 1px solid #eee;
    border-right: 0;
  }
  .width-50 .bb-pro-box .bb-pro-img .inner-img {
    border-bottom-left-radius: 20px;
  }
  .width-50 .bb-pro-box .bb-pro-contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .width-50 .bb-pro-box .bb-pro-contact p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .bb-pro-pagination {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bb-pro-pagination p {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .bb-bnr-details .inner-contact {
    padding: 20px;
  }
  .bb-bnr-details .inner-contact h4 {
    font-size: 26px;
  }
  .bb-bnr-details .inner-contact p {
    font-size: 16px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 420px) {
  .bb-pro-list-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .bb-pro-list-top .col-6 {
    width: 100%;
  }
  .bb-pro-list-top .bb-bl-btn {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .bb-pro-list-top .bb-select-inner {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .bb-pro-list-top .bb-select-inner .custom-select {
    width: 100px;
    -webkit-box-pack: left;
        -ms-flex-pack: left;
            justify-content: left;
  }
}
/* Single product page */
.bb-single-pro {
  margin-bottom: 24px;
}

.single-pro-slider {
  position: sticky;
  top: 0;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 20px;
}
.single-pro-slider .single-nav-thumb {
  width: 100%;
  overflow: hidden;
}
.single-pro-slider .single-nav-thumb .slick-list {
  margin: 0 -8px;
}
.single-pro-slider .single-nav-thumb .single-slide {
  padding: 0px 10px 0 10px;
  display: block !important;
}
.single-pro-slider .single-nav-thumb .slick-slide img {
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  border-radius: 15px;
}
.single-pro-slider .single-nav-thumb .slick-slide:hover img {
  border-color: #0fa968;
}
.single-pro-slider .single-nav-thumb .slick-slide.slick-current.slick-active img {
  border-color: #0fa968;
}
.single-pro-slider .single-nav-thumb .slick-arrow {
  top: 42%;
}
.single-pro-slider .single-nav-thumb button {
  background: transparent;
  outline: 0;
  border: 0;
}
.single-pro-slider .single-nav-thumb .slick-arrow.slick-prev {
  left: 0;
  right: auto;
}
.single-pro-slider .single-nav-thumb .slick-arrow {
  position: absolute;
  top: 55%;
  left: auto;
  right: auto;
  margin: 0 auto;
  font-size: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  z-index: 1;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.single-pro-slider .single-nav-thumb .slick-arrow:before {
  content: "";
  color: #686e7d;
  position: relative;
  font-size: 42px;
  width: 100%;
  line-height: 30px;
  height: 30px;
}
.single-pro-slider .single-nav-thumb .slick-arrow.slick-prev:before {
  font-family: "remixicon";
  content: "\ea52";
}
.single-pro-slider .single-nav-thumb .slick-arrow.slick-next {
  right: 0;
  left: auto;
}
.single-pro-slider .single-nav-thumb .slick-arrow.slick-next:before {
  font-family: "remixicon";
  content: "\ea54";
}
.single-pro-slider .single-product-cover .zoom-image-hover {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.single-pro-slider .single-product-cover .zoom-image-hover img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bb-single-pro-contact .bb-sub-title {
  margin-bottom: 20px;
}
.bb-single-pro-contact .bb-sub-title h4 {
  font-size: 22px;
  font-weight: 700;
  color: #3d4750;
}
.bb-single-pro-contact .bb-single-rating {
  margin-bottom: 12px;
}
.bb-single-pro-contact .bb-single-rating .bb-pro-rating {
  margin-right: 10px;
}
.bb-single-pro-contact .bb-single-rating .bb-read-review a {
  color: #0fa968;
}
.bb-single-pro-contact .bb-single-price-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px 0;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price {
  padding: 15px 0;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .price {
  margin-bottom: 8px;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .price h5 {
  font-size: 20px;
  font-weight: 800;
  color: #3d4750;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .price h5 span {
  font-size: 20px;
  color: #0fa968;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .sku {
  margin-bottom: 8px;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .sku h5 {
  font-size: 18px;
  font-weight: 800;
  color: #3d4750;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .mrp p {
  font-size: 16px;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .mrp p span {
  font-size: 15px;
  text-decoration: line-through;
}
.bb-single-pro-contact .bb-single-price-wrap .bb-single-price .stock span {
  font-size: 18px;
  color: #0fa968;
}
.bb-single-pro-contact .bb-single-pro-weight {
  margin-bottom: 24px;
}
.bb-single-pro-contact .bb-single-pro-weight .pro-title {
  margin-bottom: 12px;
}
.bb-single-pro-contact .bb-single-pro-weight .pro-title h4 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.bb-single-pro-contact .bb-single-pro-weight .bb-pro-variation-contant ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -2px;
}
.bb-single-pro-contact .bb-single-pro-weight .bb-pro-variation-contant ul li {
  margin: 10px 2px;
  padding: 2px 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
}
.bb-single-pro-contact .bb-single-pro-weight .bb-pro-variation-contant ul li span {
  font-size: 14px;
}
.bb-single-pro-contact .bb-single-pro-weight .bb-pro-variation-contant ul li.active-variation {
  background-color: #0fa968;
  color: #fff;
}
.bb-single-pro-contact .bb-single-list {
  margin-bottom: 30px;
}
.bb-single-pro-contact .bb-single-list ul {
  margin: -8px 0;
  padding-left: 18px;
}
.bb-single-pro-contact .bb-single-list ul li {
  margin: 8px 0;
  color: #777;
  font-size: 14px;
  list-style-type: disc;
}
.bb-single-pro-contact .bb-single-qty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -2px;
}
.bb-single-pro-contact .bb-single-qty .qty-plus-minus {
  margin: 2px;
}
.bb-single-pro-contact .bb-single-qty .buttons {
  margin: 2px;
}
.bb-single-pro-contact .bb-single-qty .buttons a {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 25px;
}
.bb-single-pro-contact .bb-single-qty .bb-pro-actions {
  margin: 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-single-pro-contact .bb-single-qty .bb-pro-actions a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 40px;
  height: 40px;
  margin: 0 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-decoration: none;
  color: #fff;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 10px;
}
.bb-single-pro-contact .bb-single-qty .bb-pro-actions a i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  color: #777;
  line-height: 10px;
}
.bb-single-pro-contact .bb-single-qty .bb-pro-actions a:hover {
  background-color: #0fa968;
}
.bb-single-pro-contact .bb-single-qty .bb-pro-actions a:hover i {
  color: #fff;
}

.bb-single-pro-tab .bb-pro-tab {
  margin-bottom: 24px;
}

.bb-inner-tabs,
.bey-single-accordion {
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 20px;
}
.bb-inner-tabs .bb-details p,
.bey-single-accordion .bb-details p {
  margin-bottom: 12px;
  font-weight: 300;
}
.bb-inner-tabs .bb-details .details-info ul,
.bey-single-accordion .bb-details .details-info ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 0;
}
.bb-inner-tabs .bb-details .details-info ul li,
.bey-single-accordion .bb-details .details-info ul li {
  padding: 5px 0;
  font-size: 15px;
  color: #686e7d;
  line-height: 28px;
}
.bb-inner-tabs .bb-details .details-info ul li span,
.bey-single-accordion .bb-details .details-info ul li span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 500;
  min-width: 150px;
}
.bb-inner-tabs .information ul,
.bey-single-accordion .information ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 0;
}
.bb-inner-tabs .information ul li,
.bey-single-accordion .information ul li {
  padding: 5px 0;
  font-size: 15px;
  color: #686e7d;
  line-height: 28px;
}
.bb-inner-tabs .information ul li span,
.bey-single-accordion .information ul li span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 500;
  min-width: 130px;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box,
.bey-single-accordion .bb-reviews .reviews-bb-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box .inner-image,
.bey-single-accordion .bb-reviews .reviews-bb-box .inner-image {
  margin-right: 12px;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box .inner-image img,
.bey-single-accordion .bb-reviews .reviews-bb-box .inner-image img {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box .inner-contact h4,
.bey-single-accordion .bb-reviews .reviews-bb-box .inner-contact h4 {
  margin-top: 5px;
  font-size: 16px;
  font-weight: 700;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box .inner-contact .bb-pro-rating,
.bey-single-accordion .bb-reviews .reviews-bb-box .inner-contact .bb-pro-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-inner-tabs .bb-reviews .reviews-bb-box .inner-contact p,
.bey-single-accordion .bb-reviews .reviews-bb-box .inner-contact p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
}
.bb-inner-tabs .bb-reviews-form h3,
.bey-single-accordion .bb-reviews-form h3 {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-inner-tabs .bb-reviews-form .bb-review-rating,
.bey-single-accordion .bb-reviews-form .bb-review-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
}
.bb-inner-tabs .bb-reviews-form .bb-review-rating span,
.bey-single-accordion .bb-reviews-form .bb-review-rating span {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #3d4750;
}
.bb-inner-tabs .bb-reviews-form .input-box,
.bey-single-accordion .bb-reviews-form .input-box {
  margin-bottom: 24px;
}
.bb-inner-tabs .bb-reviews-form .input-box input,
.bb-inner-tabs .bb-reviews-form .input-box textarea,
.bey-single-accordion .bb-reviews-form .input-box input,
.bey-single-accordion .bb-reviews-form .input-box textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #eee;
  padding-left: 20px;
  outline: 0;
  font-size: 14px;
  font-weight: 400;
  color: #777;
  border-radius: 20px;
}
.bb-inner-tabs .bb-reviews-form .input-box input::-webkit-input-placeholder, .bb-inner-tabs .bb-reviews-form .input-box textarea::-webkit-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box input::-webkit-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.bb-inner-tabs .bb-reviews-form .input-box input::-moz-placeholder, .bb-inner-tabs .bb-reviews-form .input-box textarea::-moz-placeholder, .bey-single-accordion .bb-reviews-form .input-box input::-moz-placeholder, .bey-single-accordion .bb-reviews-form .input-box textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.bb-inner-tabs .bb-reviews-form .input-box input:-ms-input-placeholder, .bb-inner-tabs .bb-reviews-form .input-box textarea:-ms-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box input:-ms-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.bb-inner-tabs .bb-reviews-form .input-box input::-ms-input-placeholder, .bb-inner-tabs .bb-reviews-form .input-box textarea::-ms-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box input::-ms-input-placeholder, .bey-single-accordion .bb-reviews-form .input-box textarea::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.bb-inner-tabs .bb-reviews-form .input-box input::placeholder,
.bb-inner-tabs .bb-reviews-form .input-box textarea::placeholder,
.bey-single-accordion .bb-reviews-form .input-box input::placeholder,
.bey-single-accordion .bb-reviews-form .input-box textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777;
}
.bb-inner-tabs .bb-reviews-form .input-box textarea,
.bey-single-accordion .bb-reviews-form .input-box textarea {
  padding: 10px 10px 10px 20px;
  height: 100px;
}
.bb-inner-tabs .bb-reviews-form .input-button a,
.bey-single-accordion .bb-reviews-form .input-button a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 4px 15px;
}
.bb-inner-tabs .accordion,
.bey-single-accordion .accordion {
  margin: -24px 0;
}
.bb-inner-tabs .accordion .accordion-item,
.bey-single-accordion .accordion .accordion-item {
  border-radius: 0;
  border: 0;
  margin: 24px 0;
}
.bb-inner-tabs .accordion .accordion-item .accordion-header .accordion-button,
.bey-single-accordion .accordion .accordion-item .accordion-header .accordion-button {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
  border: 1px solid #eee;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #3d4750;
}

/* Responsive footer css */
@media only screen and (max-width: 991px) {
  .single-pro-slider {
    max-width: 500px;
    margin: auto;
  }
}
@media only screen and (max-width: 575px) {
  .bb-inner-tabs .bb-reviews .reviews-bb-box,
  .bey-single-accordion .bb-reviews .reviews-bb-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-inner-tabs .bb-reviews .reviews-bb-box .inner-image,
  .bey-single-accordion .bb-reviews .reviews-bb-box .inner-image {
    margin-right: 0;
    margin-bottom: 12px;
  }
}
/* Contact */
.bb-contact-form {
  border: 1px solid #eee;
  border-radius: 20px;
  padding: 30px;
}
.bb-contact-form .bb-contact-wrap {
  margin-bottom: 24px;
}
.bb-contact-form .bb-contact-wrap input,
.bb-contact-form .bb-contact-wrap textarea {
  width: 100%;
  height: 50px;
  padding: 10px 10px 10px 15px;
  border: 1px solid #eee;
  outline: 0;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  border-radius: 10px;
}
.bb-contact-form .bb-contact-wrap input::-webkit-input-placeholder, .bb-contact-form .bb-contact-wrap textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-contact-form .bb-contact-wrap input::-moz-placeholder, .bb-contact-form .bb-contact-wrap textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-contact-form .bb-contact-wrap input:-ms-input-placeholder, .bb-contact-form .bb-contact-wrap textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-contact-form .bb-contact-wrap input::-ms-input-placeholder, .bb-contact-form .bb-contact-wrap textarea::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-contact-form .bb-contact-wrap input::placeholder,
.bb-contact-form .bb-contact-wrap textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-contact-form .bb-contact-wrap textarea {
  height: 150px;
}
.bb-contact-form .bb-contact-button button {
  padding: 4px 25px;
}

.bb-contact-maps {
  position: sticky;
  top: 0;
}
.bb-contact-maps iframe {
  width: 100%;
  height: 577px;
  margin-bottom: -10px;
  border-radius: 20px;
}

/* Cart */
.bb-cart-sidebar-block {
  padding: 20px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-cart-sidebar-block .bb-sb-title {
  margin-bottom: 20px;
}
.bb-cart-sidebar-block .bb-sb-title h3 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box {
  margin-bottom: 30px;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box label {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #3d4750;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box .custom-select {
  padding: 10px 15px;
  border: 1px solid #eee;
  border-radius: 10px;
  background-color: #fff;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box .custom-select .custom-select {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #eee;
  outline: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  background-color: #fff;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-sb-blok-contact .input-box input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-cart-summary .inner-summary ul li {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-cart-sidebar-block .bb-cart-summary .inner-summary ul li span {
  font-size: 14px;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-cart-summary .inner-summary ul li span .bb-coupon {
  font-size: 14px;
  font-weight: 500;
  color: #ff0000;
  cursor: pointer;
}
.bb-cart-sidebar-block .bb-cart-summary .inner-summary ul li .text-left {
  font-weight: 500;
}
.bb-cart-sidebar-block .bb-cart-summary .inner-summary ul li .text-right {
  font-weight: 600;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total {
  border-top: 1px solid #eee;
  padding-top: 15px;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total ul {
  margin-bottom: 0;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total ul li {
  margin-bottom: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total ul li span {
  font-size: 16px;
  color: #686e7d;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total ul li .text-left {
  font-weight: 600;
}
.bb-cart-sidebar-block .bb-cart-summary .summary-total ul li .text-right {
  font-weight: 600;
}

.coupon-down-box {
  width: 100%;
}
.coupon-down-box form {
  position: relative;
  margin-bottom: 15px;
}
.coupon-down-box form input {
  width: 100%;
}
.coupon-down-box form button {
  margin: 8px 8px 8px 0;
  padding: 2px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 13px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.check-btn {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.bb-cart-table {
  border: 1px solid #eee;
  border-radius: 20px;
  overflow: hidden;
}
.bb-cart-table table {
  width: 100%;
}
.bb-cart-table table thead tr {
  border-bottom: 1px solid #eee;
}
.bb-cart-table table thead tr th {
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #3d4750;
  text-transform: capitalize;
}
.bb-cart-table table tbody tr:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.bb-cart-table table tbody tr td {
  padding: 12px;
}
.bb-cart-table table tbody .Product-cart img {
  width: 70px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.bb-cart-table table tbody .Product-cart span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-cart-table table tbody span.price {
  font-size: 15px;
  font-weight: 500;
  color: #686e7d;
}
.bb-cart-table table tbody .pro-remove i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  color: #686e7d;
}
.bb-cart-table table tbody .pro-remove i:hover {
  color: #ff0000;
}

/* Responsive */
@media screen and (max-width: 1399px) {
  .bb-cart-table {
    overflow-y: auto;
  }
  .bb-cart-table::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    padding: 5px;
  }
  .bb-cart-table::-webkit-scrollbar-track {
    width: 8px;
    background-color: #eee;
    -webkit-box-shadow: inset 0 0 0px #000;
  }
  .bb-cart-table::-webkit-scrollbar-thumb {
    background-color: #3d4750;
    border-radius: 20px;
  }
  .bb-cart-table table {
    width: 780px;
  }
}
/* checkout */
.bb-checkout-sidebar {
  margin-bottom: -24px;
}
.bb-checkout-sidebar .checkout-items {
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 24px;
}
.bb-checkout-sidebar .checkout-items .sub-title {
  margin-bottom: 12px;
}
.bb-checkout-sidebar .checkout-items .sub-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-checkout-sidebar .checkout-items .payment-img img {
  width: 100%;
}
.bb-checkout-sidebar .checkout-summary {
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.bb-checkout-sidebar .checkout-summary ul {
  margin-bottom: 20px;
}
.bb-checkout-sidebar .checkout-summary ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-checkout-sidebar .checkout-summary ul li:not(:last-child) {
  margin-bottom: 8px;
}
.bb-checkout-sidebar .checkout-summary ul li span {
  font-size: 14px;
  font-weight: 500;
  color: #686e7d;
}
.bb-checkout-sidebar .checkout-summary ul li span .apply {
  font-size: 14px;
  font-weight: 500;
  color: #ff0000;
}
.bb-checkout-sidebar .bb-checkout-pro {
  margin-bottom: -24px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items {
  padding: 15px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .image {
  margin-right: 15px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .image img {
  width: 100px;
  height: 100px;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact h4 {
  font-size: 16px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact h4 a {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #3d4750;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact .bb-pro-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact .inner-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  margin-bottom: 4px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact .inner-price .new-price {
  color: #3d4750;
  font-weight: 600;
  font-size: 15px;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact .inner-price .old-price {
  font-size: 14px;
  margin-left: 10px;
  text-decoration: line-through;
  color: #777;
}
.bb-checkout-sidebar .bb-checkout-pro .pro-items .items-contact .bb-pro-variation {
  margin: 0;
}
.bb-checkout-sidebar .checkout-method {
  margin-bottom: 24px;
}
.bb-checkout-sidebar .checkout-method span.details {
  font-size: 15px;
  font-weight: 500;
  color: #686e7d;
}
.bb-checkout-sidebar .checkout-method .inner-del {
  width: 50%;
}
.bb-checkout-sidebar .checkout-method .bb-del-option {
  margin-top: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-checkout-sidebar .checkout-method .bb-del-option .bb-del-head {
  font-size: 15px;
  font-weight: 600;
  color: #3d4750;
}
.bb-checkout-sidebar .about-order h5 {
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #686e7d;
}
.bb-checkout-sidebar .about-order textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  border: 1px solid #eee;
  outline: 0;
  border-radius: 10px;
}
.bb-checkout-sidebar .about-order textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-checkout-sidebar .about-order textarea::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-checkout-sidebar .about-order textarea:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-checkout-sidebar .about-order textarea::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-checkout-sidebar .about-order textarea::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}

.bb-checkout-contact {
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 20px;
}
.bb-checkout-contact .main-title {
  margin-bottom: 20px;
}
.bb-checkout-contact .main-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-checkout-contact .inner-title {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #3d4750;
}
.bb-checkout-contact .checkout-radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.bb-checkout-contact .checkout-radio .radio-itens:not(:last-child) {
  margin-right: 20px;
}
.bb-checkout-contact .checkout-radio .radio-itens input {
  width: auto;
  margin-right: 2px;
}
.bb-checkout-contact .checkout-radio .radio-itens label {
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
}
.bb-checkout-contact p {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  color: #686e7d;
}
.bb-checkout-contact .inner-button {
  margin-bottom: 20px;
}
.bb-checkout-contact .inner-button a {
  display: inline-block;
  padding: 4px 25px;
}
.bb-checkout-contact form .input-item {
  margin-bottom: 24px;
}
.bb-checkout-contact form .input-item label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #3d4750;
}
.bb-checkout-contact form .input-button {
  margin-bottom: 20px;
}
.bb-checkout-contact form .input-button button {
  padding: 4px 25px;
}
.bb-checkout-contact .input-box-form {
  margin-top: 20px;
}
.bb-checkout-contact .input-box-form .input-item .custom-select {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.bb-checkout-contact .input-box-form .input-item .custom-select .custom-select {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.bb-checkout-contact .input-box-form .input-button {
  margin-bottom: 0;
}

/* Responsive */
@media screen and (max-width: 1399px) {
  .bb-checkout-sidebar .bb-checkout-pro .pro-items .image img {
    height: 80px;
    width: 80px;
  }
}
@media screen and (max-width: 480px) {
  .bb-checkout-contact .checkout-radio {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-checkout-sidebar .checkout-method .inner-del {
    width: 100%;
  }
  .bb-checkout-sidebar .checkout-method .bb-del-option {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-checkout-sidebar .checkout-method .bb-del-option .inner-del:not(:last-child) {
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 420px) {
  .bb-checkout-sidebar .bb-checkout-pro .pro-items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-checkout-sidebar .bb-checkout-pro .pro-items .image {
    margin-right: 0;
    margin-bottom: 15px;
  }
}
/* Faq */
.bb-faq-img {
  position: sticky;
  top: 0;
}
.bb-faq-img img {
  width: 100%;
  border-radius: 20px;
}

.accordion-item {
  background: transparent;
}

.accordion-button:after {
  background-image: none;
  -webkit-transform: none;
          transform: none;
  font-family: "remixicon";
  content: "\f1af";
  font-size: 20px;
  font-weight: 500;
  color: #3d4750;
}
.accordion-button:not(.collapsed):after {
  background-image: none;
  -webkit-transform: none;
          transform: none;
  font-family: "remixicon";
  content: "\ea13";
  font-size: 20px;
  font-weight: 500;
  color: #3d4750;
}

.bb-faq-contact {
  margin-bottom: -24px;
}
.bb-faq-contact .accordion-item {
  border-radius: 0;
  border: 0;
  outline: 0;
  margin-bottom: 24px;
}
.bb-faq-contact .accordion-item .accordion-button {
  background: transparent;
  border-radius: 15px !important;
  border: 1px solid #eee;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.bb-faq-contact .accordion-item .accordion-body {
  padding: 15px 15px 0;
}
.bb-faq-contact .accordion-header {
  font-size: 16px;
}
.bb-faq-contact .accordion-header .accordion-button {
  font-size: 16px;
  font-weight: 600;
  color: #3d4750;
}
.bb-faq-contact .accordion-body {
  font-size: 14px;
  font-weight: 300;
  color: #686e7d;
}

/* login */
.bb-login-contact {
  max-width: 500px;
  margin: auto;
  border: 1px solid #eee;
  padding: 30px;
  border-radius: 20px;
}
.bb-login-contact .bb-login-wrap {
  margin-bottom: 24px;
}
.bb-login-contact .bb-login-wrap a {
  font-size: 14px;
  font-weight: 500;
  color: #777;
}
.bb-login-contact .bb-login-button {
  margin: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bb-login-contact .bb-login-button button {
  margin: 5px;
  padding: 4px 20px;
}
.bb-login-contact .bb-login-button a {
  height: 36px;
  margin: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bb-login-contact .bb-login-button a:hover {
  color: #0fa968;
}

/* Register */
.bb-register {
  border: 1px solid #eee;
  padding: 30px;
  border-radius: 20px;
}
.bb-register form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -12px;
}
.bb-register form .bb-register-width-50 {
  width: 50%;
}
.bb-register form .bb-register-width-100 {
  width: 100%;
}
.bb-register form .bb-register-wrap {
  padding: 0 12px;
  margin-bottom: 24px;
}
.bb-register form .bb-register-wrap label {
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #3d4750;
}
.bb-register form .bb-register-wrap input,
.bb-register form .bb-register-wrap .custom-select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #686e7d;
  border: 1px solid #eee;
  outline: 0;
  border-radius: 10px;
}
.bb-register form .bb-register-wrap .custom-select .custom-select {
  padding: 0;
  border: 0;
}
.bb-register form .bb-register-button {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-register form .bb-register-button button {
  padding: 4px 20px;
}

/* Responsive footer css */
@media only screen and (max-width: 575px) {
  .bb-register form .bb-register-width-50 {
    width: 100%;
  }
}
/* blog */
.bb-blog-sidebar {
  margin-bottom: -24px;
}
.bb-blog-sidebar .blog-inner-contact {
  margin-bottom: 24px;
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-title {
  margin-bottom: 20px;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card {
  margin-bottom: 24px;
  padding: 15px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card:last-child {
  margin-bottom: 0;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .inner-image {
  margin-right: 15px;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .inner-image img {
  width: 80px;
  border-radius: 20px;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .blog-sidebar-contact span {
  font-size: 13px;
  font-weight: 400;
  color: #0fa968;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .blog-sidebar-contact h4 {
  font-size: 15px;
  margin-bottom: 8px;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .blog-sidebar-contact h4 a {
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: #3d4750;
}
.bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .blog-sidebar-contact p {
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  color: #686e7d;
}
.bb-blog-sidebar .blog-inner-contact .blog-categories ul {
  margin-bottom: -14px;
}
.bb-blog-sidebar .blog-inner-contact .blog-categories ul li {
  position: relative;
  margin-bottom: 14px;
}

.bb-blog-card {
  background-color: #f8f8fb;
  border: 1px solid #eee;
  border-radius: 20px;
}
.bb-blog-card .blog-image img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
}
.bb-blog-card .blog-contact {
  padding: 20px;
}
.bb-blog-card .blog-contact h5 {
  margin-bottom: 12px;
  font-size: 18px;
}
.bb-blog-card .blog-contact h5 a {
  font-size: 18px;
  font-weight: 500;
  color: #3d4750;
}
.bb-blog-card .blog-contact p {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 300;
}
.bb-blog-card .blog-contact .blog-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-blog-card .blog-contact .blog-btn a {
  padding: 2px 14px;
}

.bb-blog-details-contact .bb-pro-pagination {
  margin-bottom: 24px;
}
.bb-blog-details-contact .inner-blog-details-image {
  margin-bottom: 24px;
}
.bb-blog-details-contact .inner-blog-details-image img {
  width: 100%;
  border-radius: 15px;
}
.bb-blog-details-contact .inner-blog-details-contact {
  margin-bottom: 30px;
}
.bb-blog-details-contact .inner-blog-details-contact span {
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 300;
  color: #777;
}
.bb-blog-details-contact .inner-blog-details-contact h4.sub-title {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 700;
  color: #3d4750;
}
.bb-blog-details-contact .inner-blog-details-contact p {
  margin-bottom: 16px;
}
.bb-blog-details-contact .inner-blog-details-contact .blog-inner-image {
  margin-bottom: 24px;
}
.bb-blog-details-contact .inner-blog-details-contact .blog-inner-image img {
  width: 100%;
  border-radius: 15px;
}
.bb-blog-details-contact .bb-blog-details-comment:not(:last-child) {
  margin-bottom: 30px;
}
.bb-blog-details-contact .bb-blog-details-comment .main-title {
  margin-bottom: 12px;
}
.bb-blog-details-contact .bb-blog-details-comment .main-title h4 {
  font-size: 20px;
  font-weight: 700;
  color: #3d4750;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-pl-50 {
  padding-left: 50px;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-image {
  margin-right: 15px;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-image img {
  width: 50px;
  border-radius: 15px;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact h5.sub-title {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #3d4750;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact span {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 300;
  color: #777;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact p {
  margin-bottom: 6px;
  font-size: 14px;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact .bb-details-btn {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #0fa968;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact .bb-details-btn i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  font-weight: 600;
}
.bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-contact .bb-details-btn:hover i {
  padding-left: 4px;
}
.bb-blog-details-contact .bb-blog-details-comment form .bb-details-input {
  margin-bottom: 24px;
}
.bb-blog-details-contact .bb-blog-details-comment form .bb-details-input textarea {
  height: 200px;
}
.bb-blog-details-contact .bb-blog-details-comment form .bb-details-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-blog-details-contact .bb-blog-details-comment form .bb-details-buttons a {
  padding: 4px 18px;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .inner-image {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .bb-blog-sidebar .blog-inner-contact {
    padding: 15px;
  }
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-title h4 {
    font-size: 18px;
  }
  .bb-blog-details-contact .bb-blog-details-comment .bb-comment-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-blog-details-contact .bb-blog-details-comment .bb-comment-box .inner-image {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .bb-blog-details-contact .inner-blog-details-contact h4.sub-title {
    font-size: 20px;
  }
}
@media screen and (max-width: 360px) {
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .inner-image {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .bb-blog-sidebar .blog-inner-contact .blog-sidebar-card .inner-image img {
    width: 100%;
  }
}
/* wishlist */
.bb-wishlist .bb-pro-img .bb-remove-wish {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;
}
.bb-wishlist .bb-pro-img .bb-remove-wish i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 22px;
  color: #686e7d;
}
.bb-wishlist .bb-pro-img .bb-remove-wish i:hover {
  color: #0fa968;
}

.bb-wish-rightside .bb-wishlist-msg {
  padding: 30px 0;
  text-align: center;
  font-size: 50px;
  line-height: 32px;
  font-weight: 500;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #686e7d;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .bb-wish-rightside .bb-wishlist-msg {
    font-size: 42px;
  }
}
@media screen and (max-width: 767px) {
  .bb-wish-rightside .bb-wishlist-msg {
    font-size: 36px;
  }
}
@media screen and (max-width: 575px) {
  .bb-wish-rightside .bb-wishlist-msg {
    font-size: 32px;
  }
}
/* compare */
.bb-compare {
  overflow: auto;
}
.bb-compare::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 5px;
}
.bb-compare::-webkit-scrollbar-track {
  width: 8px;
  background-color: #eee;
  -webkit-box-shadow: inset 0 0 0px #000;
}
.bb-compare::-webkit-scrollbar-thumb {
  background-color: #3d4750;
  border-radius: 20px;
}
.bb-compare ul.bb-compare-main-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.bb-compare ul.bb-compare-main-box li.bb-compare-inner-rows:last-child {
  border-right: 1px solid #eee;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box {
  border-left: 1px solid #eee;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  width: 323px;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box .inner-image {
  position: relative;
  background-color: #fff;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box .inner-image .bb-remove-compare {
  position: absolute;
  top: 20px;
  right: 20px;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box .inner-image .bb-remove-compare i {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 22px;
  color: #686e7d;
}
.bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box .inner-image .bb-remove-compare i:hover {
  color: #0fa968;
}

.compare-pro-img {
  height: 250px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.compare-pro-img p {
  font-size: 15px;
}
.compare-pro-img img {
  height: 100%;
}

.compare-description {
  height: 135px;
}

.compare-pro-title,
.compare-pro-detail {
  padding: 20px;
}
.compare-pro-title h5,
.compare-pro-detail h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #3d4750;
}
.compare-pro-title p,
.compare-pro-detail p {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: #686e7d;
}
.compare-pro-title .in-stock,
.compare-pro-detail .in-stock {
  color: #0fa968;
}
.compare-pro-title .out-stock,
.compare-pro-detail .out-stock {
  color: #ffa5a5;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .bb-compare ul.bb-compare-main-box ul.bb-compare-inner-box {
    width: 250px;
  }
  .compare-pro-img img {
    width: 100%;
  }
}
/* Terms */
.section-terms .desc {
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 20px;
}
.section-terms .desc h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
}
.section-terms .desc p {
  font-weight: 400;
}
.section-terms .desc .block:not(:last-child) {
  margin-bottom: 24px;
}

/* Track */
.section-track .track {
  padding: 30px;
  border: 1px solid #eee;
  border-radius: 30px;
}
.section-track .block-title {
  padding: 30px;
  background-color: #f8f8fb;
  border: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 20px;
}
.section-track .block-title h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}
.section-track .block-title p {
  font-size: 14px;
  font-weight: 400;
}
.section-track .bb-progress {
  margin: -12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.section-track .bb-progress li {
  width: calc(20% - 24px);
  margin: 12px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #eee;
  border-radius: 30px;
  position: relative;
}
.section-track .bb-progress li .icon {
  margin-bottom: 5px;
}
.section-track .bb-progress li .icon i {
  font-size: 25px;
  color: #a5a8b1;
}
.section-track .bb-progress li .number {
  width: 30px;
  height: 30px;
  background-color: rgba(104, 110, 125, 0.4);
  color: #fff;
  position: absolute;
  top: 10px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 30px;
}
.section-track .bb-progress li .title {
  text-align: center;
  line-height: 22px;
  font-weight: 400;
  color: #a5a8b1;
}
.section-track .bb-progress li.active {
  border-color: #686e7d;
}
.section-track .bb-progress li.active .number {
  width: 35px;
  height: 35px;
  background-color: rgba(104, 110, 125, 0.9);
}
.section-track .bb-progress li.active .icon {
  margin-bottom: 5px;
}
.section-track .bb-progress li.active .icon i {
  color: #686e7d;
}
.section-track .bb-progress li.active .title {
  font-weight: 600;
  color: #686e7d;
}

/* Responsive */
@media screen and (max-width: 991px) {
  .section-track .bb-progress li {
    width: calc(50% - 24px);
  }
}
@media screen and (max-width: 480px) {
  .section-track .bb-progress li {
    width: 100%;
  }
  .section-track .track {
    padding: 15px;
  }
}
/*# sourceMappingURL=style.css.map */