.block{
  display: block;
}
.none{
  display: none;
}
.product-img{
  object-fit: cover;
  width: 100%;
  height: 250px;
}